import ZUSD from "../../assets/ZUSD.png";
import ZNGN from "../../assets/ZNGN.png";
import ZXAF from "../../assets/ZXAF.png";
import ZZAR from "../../assets/ZZAR.png";
import { PiSpinnerBold } from "react-icons/pi";
import { IoPersonCircleOutline } from "react-icons/io5";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";

interface transferProps {
  fromZAsset: string;
  toZAsset: string;
  toAmount: any;
  fromAmount: any;
  selectToZAsset: (arg: string) => void;
  selectFromZAsset: (arg: string) => void;
  setFromAmount: (arg: any) => void;
  setToAmount: (arg: any) => void;
  zUSDBal: any;
  zNGNBal: any;
  zXAFBal: any;
  zZARBal: any;
  showFromZAssets: boolean;
  setShowFromZAssets: (arg: React.SetStateAction<boolean>) => void;
  showToZAssets: boolean;
  setShowToZAssets: (arg: React.SetStateAction<boolean>) => void;
  fee: any;
  rate: any;
  _approve: boolean;
  loading: boolean;
  handleApprove: any;
  handleSwap: any;
  handleFields: any;
  setAddress: any;
  handleTransfer: any;
  iSwap: boolean;
  transferApprove: boolean;
}

const TransferCard = ({
  fromZAsset,
  toZAsset,
  fromAmount,
  toAmount,
  selectToZAsset,
  selectFromZAsset,
  setFromAmount,
  setToAmount,
  zUSDBal,
  zNGNBal,
  zXAFBal,
  zZARBal,
  showFromZAssets,
  setShowFromZAssets,
  showToZAssets,
  setShowToZAssets,
  fee,
  rate,
  _approve,
  loading,
  handleApprove,
  handleSwap,
  handleFields,
  setAddress,
  handleTransfer,
  iSwap,
  transferApprove,
}: transferProps) => {
  return (
    <div className="w-full max-w-md h-min bg-[#FFFAF3] border-thin border-gray-400 rounded-md py-3 px-3 shadow-md transfer-zToken">
      <p className="hidden md:block text-xs mx-auto w-full mb-3 text-center mt-2">
        Select the asset you would like to send and that which they should
        RECEIVE
      </p>
      <div className="space-y-3">
        <label htmlFor="walletAddress" className="w-full">
          <p className="text-start capitalize text-[x-small] mb-1">
            RECIPIENT'S WALLET
          </p>
          <div className="inline-flex bg-white border-thin border-gray-400 w-full p-2 gap-1 rounded-md">
            <IoPersonCircleOutline className="self-center h-5 w-5" />
            <input
              type="text"
              name="walletAddress"
              id="walletAddress"
              onChange={(e: any) => setAddress(e.target.value)}
              className="placeholder:text-[x-small] focus:outline-none placeholder:my-auto text-sm align-text-top py-1 pb-[34px] w-full h-full rounded-sm"
              placeholder="wallet address"
            />
          </div>
        </label>

        <label htmlFor="from" className="w-full">
          <div className="inline-flex justify-between w-full text-end mb-1 text-[x-small]">
            <p className="">YOU SEND</p>
            <div className="">
              {fromZAsset === "zUSD" && (
                <p onClick={() => setFromAmount(zUSDBal)} className="">
                  <span className="me-1">{fromZAsset} Balance:</span>
                  <span className="">
                    {zUSDBal?.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </p>
              )}
              {fromZAsset === "zNGN" && (
                <p onClick={() => setFromAmount(zNGNBal)} className="">
                  <span className="me-1">{fromZAsset} Balance:</span>
                  <span className="">
                    {zNGNBal?.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </p>
              )}
              {fromZAsset === "zXAF" && (
                <p onClick={() => setFromAmount(zXAFBal)} className="">
                  <span className="me-1">{fromZAsset} Balance:</span>
                  <span className="">
                    {zXAFBal?.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </p>
              )}
              {fromZAsset === "zZAR" && (
                <p onClick={() => setFromAmount(zZARBal)} className="">
                  Balance:
                  <span className="">
                    {zZARBal?.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </p>
              )}
            </div>
          </div>

          <div className="inline-flex bg-white border-thin border-gray-400 w-full p-2 gap-1 rounded-md">
            <div
              className="relative inline-flex items-center border-r-thin w-max gap-3 pe-1 border-gray-400"
              onClick={() => setShowFromZAssets(!showFromZAssets)}
            >
              <span className="flex space-x-1 items-center">
                {fromZAsset && (
                  <>
                    {fromZAsset === "zUSD" && (
                      <img src={ZUSD} alt="ZUSD" className="h-5" />
                    )}
                    {fromZAsset === "zNGN" && (
                      <img src={ZNGN} alt="ZNGN" className="h-5" />
                    )}
                    {fromZAsset === "zXAF" && (
                      <img src={ZXAF} alt="ZXAF" className="h-5" />
                    )}
                    {fromZAsset === "zZAR" && (
                      <img src={ZZAR} alt="ZZAR" className="h-5" />
                    )}
                  </>
                )}
                <p className="text-xs">
                  {fromZAsset ? fromZAsset : "Choose Asset"}
                </p>
              </span>
              {showFromZAssets ? (
                <AiOutlineUp className="text-[#5A5A65] h-3" />
              ) : (
                <AiOutlineDown className="text-[#5A5A65] h-3" />
              )}
              {showFromZAssets && (
                <div className="flex z-20 flex-col py-2 text-font-grey cursor-pointer absolute rounded bg-white min-w-max text-sm space-y-1.5 border-thin border-gray-400 -right-1 top-[110%]">
                  <div
                    className="inline-flex gap-1 hover:bg-[#EAD0A9] px-2 py-0.5 duration-300 ease-out"
                    onClick={() => selectFromZAsset("zUSD")}
                  >
                    <img src={ZUSD} alt="zusd" className="h-5" />
                    <p className="ml-2">zUSD</p>
                  </div>
                  <div
                    className="inline-flex gap-1 hover:bg-[#EAD0A9] px-2 py-0.5 duration-300 ease-out"
                    onClick={() => selectFromZAsset("zNGN")}
                  >
                    <img src={ZNGN} alt="" className="h-5" />
                    <p className="ml-2">zNGN</p>
                  </div>
                  <div
                    className="inline-flex gap-1 hover:bg-[#EAD0A9] px-2 py-0.5 duration-300 ease-out"
                    onClick={() => selectFromZAsset("zXAF")}
                  >
                    <img src={ZXAF} alt="" className="h-5" />
                    <p className="ml-2">zXAF</p>
                  </div>
                  <div
                    className="inline-flex gap-1 hover:bg-[#EAD0A9] px-2 py-0.5 duration-300 ease-out"
                    onClick={() => selectFromZAsset("zZAR")}
                  >
                    <img src={ZZAR} alt="" className="h-5" />
                    <p className="ml-2">zZAR</p>
                  </div>
                </div>
              )}
            </div>
            <input
              type="number"
              name="from"
              placeholder="0"
              value={fromAmount}
              onChange={(e) => handleFields(e.target.value, "From")}
              disabled={fromZAsset && toZAsset ? false : true}
              className="w-full px-2 text-right bg-transparent focus:outline-none"
            />
          </div>
        </label>

        <label htmlFor="to" className="w-full">
          <div className="w-full inline-flex justify-between text-end mb-1 text-[x-small]">
            <p className="">THEY RECEIVE</p>
            <div className="">
              {toZAsset === "zUSD" && (
                <p onClick={() => setToAmount(zUSDBal)} className="">
                  <span className="me-1">{toZAsset} Balance:</span>
                  <span className="">
                    {zUSDBal?.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </p>
              )}
              {toZAsset === "zNGN" && (
                <p onClick={() => setToAmount(zNGNBal)}>
                  <span className="me-1">{toZAsset} Balance:</span>
                  <span className="">
                    {zNGNBal?.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </p>
              )}
              {toZAsset === "zXAF" && (
                <p onClick={() => setToAmount(zXAFBal)} className="">
                  <span className="me-1">{toZAsset} Balance:</span>
                  <span className="">
                    {zXAFBal?.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </p>
              )}
              {toZAsset === "zZAR" && (
                <p onClick={() => setToAmount(zZARBal)} className="">
                  <span className="me-1">{toZAsset} Balance:</span>
                  <span className="">
                    {zZARBal?.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </p>
              )}
            </div>
          </div>

          <div className="inline-flex bg-white border-thin border-gray-400 w-full p-2 gap-3 rounded-md">
            <div
              className="relative inline-flex items-center border-r-thin w-max gap-3 pe-1 border-gray-400"
              onClick={() => setShowToZAssets(!showToZAssets)}
            >
              <span className="inline-flex space-x-1 me-1 text-xs">
                {toZAsset && (
                  <>
                    {toZAsset === "zUSD" && (
                      <img src={ZUSD} alt="ZUSD" className="h-5" />
                    )}
                    {toZAsset === "zNGN" && (
                      <img src={ZNGN} alt="ZNGN" className="h-5" />
                    )}
                    {toZAsset === "zXAF" && (
                      <img src={ZXAF} alt="ZXAF" className="h-5" />
                    )}
                    {toZAsset === "zZAR" && (
                      <img src={ZZAR} alt="ZZAR" className="h-5" />
                    )}
                  </>
                )}
                <p className="text-xs">
                  {toZAsset ? toZAsset : "Choose Asset"}
                </p>
              </span>
              {showToZAssets ? (
                <AiOutlineUp className="text-[#5A5A65] h-3" />
              ) : (
                <AiOutlineDown className="text-[#5A5A65] h-3" />
              )}
              {showToZAssets && (
                <div className="flex flex-col py-2 z-10 text-font-grey cursor-pointer absolute rounded bg-white min-w-max text-sm space-y-1.5 border-thin border-gray-400 -right-1 top-[110%]">
                  <div
                    className="inline-flex gap-1 hover:bg-[#EAD0A9] px-2 py-0.5 duration-300 ease-out"
                    onClick={() => selectToZAsset("zUSD")}
                  >
                    <img src={ZUSD} alt="zusd" className="h-5" />
                    <p className="ml-2">zUSD</p>
                  </div>
                  <div
                    className="inline-flex gap-1 hover:bg-[#EAD0A9] px-2 py-0.5 duration-300 ease-out"
                    onClick={() => selectToZAsset("zNGN")}
                  >
                    <img src={ZNGN} alt="" className="h-5" />
                    <p className="ml-2">zNGN</p>
                  </div>
                  <div
                    className="inline-flex gap-1 hover:bg-[#EAD0A9] px-2 py-0.5 duration-300 ease-out"
                    onClick={() => selectToZAsset("zXAF")}
                  >
                    <img src={ZXAF} alt="" className="h-5" />
                    <p className="ml-2">zXAF</p>
                  </div>
                  <div
                    className="inline-flex gap-1 hover:bg-[#EAD0A9] px-2 py-0.5 duration-300 ease-out"
                    onClick={() => selectToZAsset("zZAR")}
                  >
                    <img src={ZZAR} alt="" className="h-5" />
                    <p className="ml-2">zZAR</p>
                  </div>
                </div>
              )}
            </div>
            <input
              type="number"
              name="to"
              placeholder="0"
              value={toAmount}
              onChange={(e) => handleFields(e.target.value, "To")}
              disabled={toZAsset && toZAsset ? false : true}
              className="w-full px-2 text-right focus:outline-none"
            />
          </div>
        </label>

        <div className="w-full text-[#794118] text-xs space-y-1">
          <div className="flex justify-between">
            <p className=""> Exchange Rate:</p>
            <p className="">
              1 {fromZAsset} = {rate?.toFixed(2)} {toZAsset}
            </p>
          </div>
          <div className="flex justify-between">
            <p className=""> Transfer Fee:</p>
            <p className="">{fee}%</p>
          </div>
        </div>

        <button
          onClick={() =>
            !_approve
              ? handleApprove()
              : iSwap
              ? handleSwap()
              : handleTransfer()
          }
          className={`w-full ${
            !_approve ? "bg-[#FB7F37]" : "bg-black"
          } text-white rounded p-2 text-sm mt-4 flex justify-center items-center`}
        >

          {iSwap ? (
            <>
              {!_approve ? (
                <>
                  {loading ? (
                    <PiSpinnerBold className="h-4 w-4 animate-spin" />
                  ) : (
                    "Approve Swap"
                  )}
                </>
              ) : (
                <>
                  {loading ? (
                    <PiSpinnerBold className="h-4 w-4 animate-spin" />
                  ) : (
                    "Swap"
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {loading ? (
                <>
                  {transferApprove
                    ? "Confirming transfer"
                    : "Approving transfer"}
                  <PiSpinnerBold className="h-4 w-4 animate-spin ml-3" />
                </>
              ) : (
                "Transfer"
              )}
            </>
          )}
        </button>
        {/* </label> */}
      </div>
    </div>
  );
};

export default TransferCard;
