import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import TourContext from "../../../TourGuideContext";
import { IoChevronUp } from "react-icons/io5";
import { RxDiscordLogo } from "react-icons/rx";
import { PiTelegramLogo, PiStudent } from "react-icons/pi";
// import { motion } from "framer-motion";

const FloatingButton = () => {
  const [open, setOpen] = useState(false);
  const { setShowGuide } = useContext(TourContext);
  const navigate = useNavigate();

  return (
    <div className="absolute bottom-20 right-3">
      <div className="relative inline-flex gap-1">
        {open && (
          <>
            <button
              className={`block bg-white rounded-full border border-gray-400 shadow-xl `}
            >
              <a
                href="https://discord.com/invite/JddhFeYUGB"
                target="_blank"
                rel="noreferrer"
              >
                <RxDiscordLogo className="m-2" />
              </a>
            </button>
            <button
              className={`block bg-white rounded-full border border-gray-400 shadow-xl `}
            >
              <a
                href="https://t.me/canzafinance"
                target="_blank"
                rel="noreferrer"
              >
                <PiTelegramLogo className="m-2" />
              </a>
            </button>
            <button
              className={`block bg-white rounded-full border border-gray-400 shadow-xl `}
              onClick={async () => {
                await navigate("/mint");
                setShowGuide(true);
              }}
            >
              <PiStudent className="m-2" />
            </button>
          </>
        )}
        <button
          className={` bg-white rounded-full border border-gray-400 shadow-xl ${
            open && "-rotate-90"
          } transition-all duration-300`}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <IoChevronUp className="m-2" />
        </button>
      </div>
    </div>
  );
};

export default FloatingButton;
