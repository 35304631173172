/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import vaultAbi from "../contracts/vault.json";
import oracleABI from "../contracts/oracle.json";
import { ethers } from "ethers";
import useConfigs from "./useConfigs";
import { useChainId } from "wagmi";

const useHome = () => {
  const chain = useChainId();
  const config = useConfigs();

  let provider = new ethers.providers.JsonRpcProvider(
    chain === 43114
      ? "https://api.avax.network/ext/bc/C/rpc"
      : "https://api.avax-test.network/ext/bc/C/rpc"
  );

  const [contract, setContract] = useState<any>(null);
  const [oracle, setOracle] = useState<any>(null);

  const wallet = new ethers.Wallet(
    "37da3265e408fabfe1a9d4a6b6d1a3772f6e9836e0488cb2e3750f9a94767e8e",
    provider
  );

  useEffect(() => {
    if (provider) {
      setContract(
        new ethers.Contract(String(config?.vaultAddress), vaultAbi, wallet)
      );
      setOracle(
        new ethers.Contract(String(config?.oracleAddress), oracleABI, wallet)
      );
    }
  }, [chain]);

  return { contract, oracle };
};

export default useHome;
