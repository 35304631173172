/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ethers } from "ethers";
import { useContext, useEffect } from "react";
import useSigner from "./useSigner";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCollateral,
  updateUserCollateral,
  updateTransactions,
} from "../redux/reducers/bakiReducer";
import useOracle from "./useOracle";
import useConfigs from "./useConfigs";
import AppContext from "../context";
// import { notifySlack } from "../utils";
import useToken from "./useToken";
import { useNetwork } from "wagmi";
import { toast } from "react-toastify";
import transactionBody from "./transactionBody";
declare const window: any;

const useDeposit = () => {
  const config = useConfigs();
  const { decimals } = useToken("USDC", false);
  const { chain }: any = useNetwork();

  const dispatch = useDispatch();
  const { address, rewardBal } = useSelector((state: any) => state.baki);
  const { getCOLUSD } = useOracle();
  const { contract } = useSigner();

  useEffect(() => {
    if (contract && decimals) {
      getValues();
    }
  }, [decimals, contract]);

  const getValues = async () => {
    // Get Coll balance
    let ball = await contract?.getBalance(config?.USDC);
    dispatch(
      updateCollateral(Number(ethers.utils.formatUnits(ball, decimals)))
    );

    let colBall = await contract?.getUserCollateralBalance();
    dispatch(
      updateUserCollateral(Number(ethers.utils.formatUnits(colBall, 18)))
    );
  };
  const deposit = async (depositAmount: number, mintAmount: number) => {
    let hash = "";
    try {
      let transactions: any = {};
      let _transactions = [];
      let transaction = transactionBody(chain);

      let date = new Date();
      transaction.date = `${date.getDate()}/${
        date.getMonth() + 1
      }/${date.getFullYear()}`;

      const tx = await contract?.depositAndMint(
        ethers.utils.parseUnits(String(depositAmount), 18),
        ethers.utils.parseUnits(String(mintAmount), 18)
      );

      await tx.wait();
      const txns = await window.localStorage.getItem("transactions");
      transaction.depositBody.colAmount = depositAmount;
      transaction.depositBody.mintAmount = mintAmount;
      transaction.action = "Deposit";
      transaction.status = "Successful";
      transaction.hash = tx.hash;
      hash = tx.hash;

      if (txns) {
        if (JSON.parse(txns)[address]?.length < 10) {
          _transactions = JSON.parse(txns)[address];
          _transactions.push(transaction);
        } else {
          _transactions.push(transaction);
        }
      } else {
        _transactions.push(transaction);
      }

      transactions[address] = _transactions.reverse();

      await window.localStorage.setItem(
        "transactions",
        JSON.stringify(transactions)
      );
      dispatch(updateTransactions(transactions));

      return true;
    } catch (err: any) {
      let transactions: any = {};
      let _transactions = [];
      let transaction = transactionBody(chain);
      let date = new Date();
      transaction.date = `${date.getDate()}/${
        date.getMonth() + 1
      }/${date.getFullYear()}`;
      transaction.depositBody.colAmount = depositAmount;
      transaction.depositBody.mintAmount = mintAmount;
      transaction.action = "Deposit";
      transaction.status = "Failed";
      transaction.hash = hash;

      const txns = await window.localStorage.getItem("transactions");

      if (txns) {
        if (JSON.parse(txns)[address]?.length < 10) {
          _transactions = JSON.parse(txns)[address];
          _transactions.push(transaction);
        } else {
          _transactions.push(transaction);
        }
      } else {
        _transactions.push(transaction);
      }

      transactions[address] = _transactions.reverse();

      await window.localStorage.setItem(
        "transactions",
        JSON.stringify(transactions)
      );

      toast.error(String(err));
      console.error(err);
      // notifySlack(err, 'useDeposit.deposit', {depositAmount, mintAmount}, address)
      return false;
    }
  };

  const claimReward = async () => {
    let hash = "";
    try {
      let transactions: any = {};
      let _transactions = [];
      let transaction = transactionBody(chain);
      let date = new Date();
      transaction.date = `${date.getDate()}/${
        date.getMonth() + 1
      }/${date.getFullYear()}`;

      const tx = await contract?.claimFees();
      await tx.wait();
      const txns = await window.localStorage.getItem("transactions");
      transaction.rewardBody.amount = rewardBal * 10 ** -6;
      transaction.action = "Reward";
      transaction.status = "Successful";
      transaction.hash = tx?.hash;
      hash = tx?.hash;
      if (txns) {
        if (JSON.parse(txns)[address]?.length <= 10) {
          _transactions = JSON.parse(txns)[address];
          _transactions.push(transaction);
        } else {
          _transactions.push(transaction);
        }
      } else {
        _transactions.push(transaction);
      }

      transactions[address] = _transactions.reverse();

      await window.localStorage.setItem(
        "transactions",
        JSON.stringify(transactions)
      );
      dispatch(updateTransactions(transactions));

      return true;
    } catch (err: any) {
      let transactions: any = {};
      let _transactions = [];
      let transaction = transactionBody(chain);

      const txns = await window.localStorage.getItem("transactions");
      let date = new Date();
      transaction.date = `${date.getDate()}/${
        date.getMonth() + 1
      }/${date.getFullYear()}`;
      transaction.rewardBody.amount = rewardBal;
      transaction.action = "Reward";
      transaction.status = "Failed";
      transaction.hash = hash;
      if (txns) {
        if (JSON.parse(txns)[address]?.length <= 10) {
          _transactions = JSON.parse(txns)[address];
          _transactions.push(transaction);
        } else {
          _transactions.push(transaction);
        }
      } else {
        _transactions.push(transaction);
      }

      transactions[address] = _transactions.reverse();

      await window.localStorage.setItem(
        "transactions",
        JSON.stringify(transactions)
      );
      dispatch(updateTransactions(transactions));
      console.error(err);
      toast.error(String(err));
      // notifySlack(err, 'useDeposit.claimReward', {}, address)
      return false;
    }
  };

  const getUSDValue = async (_amount: number) => {
    try {
      let USDVal = await getCOLUSD();
      return _amount * USDVal;
    } catch (error) {
      console.error(error);
    }
  };

  return { deposit, claimReward, getUSDValue };
};

export default useDeposit;
