/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import "./Home.css";
import baki from "../../../assets/baki.png";
import millify from "millify";
import { Link } from "react-router-dom";
import USDC from "../../../assets/usdc.png";
import ZNGN from "../../../assets/ZNGN.png";
import ZXAF from "../../../assets/ZXAF.png";
import ZZAR from "../../../assets/ZZAR.png";
import { RiArrowRightUpLine } from "react-icons/ri";
import { InfinitySpin } from "react-loader-spinner";
import paladinMobile from "../../../assets/paladin-mobile.png";

import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";

const Home = ({
  fee,
  totalVolume,
  globalDebt,
  NGNUSD,
  XAFUSD,
  ZARUSD,
  totalCollateral,
  rateLoading,
  loading,
}: any) => {
  const [showZAssets, setShowZAssets] = useState(false);
  const [currRate, setCurrRate] = useState(NGNUSD);
  const [zToken, setZToken] = useState("zNGN");

  const months: any = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  const time = new Date();

  const utcTime = time.toISOString(); // Convert the local time to UTC

  const utcHour = parseInt(utcTime.slice(11, 13)); // Extract the hour component from the UTC time
  const utcMinute = utcTime.slice(14, 16); // Extract the minute component from the UTC time

  let period = "AM"; // Default period is AM
  let hour12 = utcHour; // Initialize the 12-hour format hour with the UTC hour

  if (hour12 >= 12) {
    period = "PM"; // If the hour is 12 or greater, it's PM
  }

  if (hour12 > 12) {
    hour12 -= 12; // If the hour is greater than 12, subtract 12 to fit the 12-hour format
  }

  const formattedTime = `${hour12}:${utcMinute} ${period}`; // Construct the formatted time string

  return (
    <div className="mobile-landing px-2">
      <div className="flex flex-col flex-1 space-y-3 w-full h-full items-center">
        <br />
        <br />
        <img src={baki} alt="" className="h-8 w-24" />
        <div className="space-y-3 text-center px-4">
          <p className="text-3xl font-semibold">
            Infinite Liquidity FX Exchange for African Currencies
          </p>
          <p className="text-sm">
            Baki provides the ability to offer infinite liquidity at the
            official conversion rate, and natively quote assets in local
            currencies on chain.
          </p>
          <div className="!mt-6">
            <img src={paladinMobile} alt="" className="h-8 mx-auto" />
          </div>
        </div>

        <div className="inline-flex w-full my-4 divide-x-thin divide-gray-400">
          <div className="basis-1/4 items-center text-center px-1">
            <div className="font-medium">
              $
              {millify(totalCollateral / 10 ** 18, {
                units: ["", "K", "M", "B", "T", "P", "E"],
                space: false,
              })}
            </div>

            <p className="text-[x-small]">Global Collateral</p>
          </div>
          <div className="basis-1/4 items-center text-center px-1">
            <div className="font-medium">
              {" "}
              $
              {millify(globalDebt / 10 ** 18, {
                units: ["", "K", "M", "B", "T", "P", "E"],
                space: false,
              })}
            </div>

            <p className="text-[x-small]">zToken Market Cap</p>
          </div>
          <div className="basis-1/4 items-center text-center px-1">
            <div className="font-medium">
              $
              {millify(Number(totalVolume / 10 ** 18), {
                units: ["", "K", "M", "B", "T", "P", "E"],
                space: false,
              })}
            </div>

            <p className="text-[x-small]">Trading Volume</p>
          </div>
          <div className="basis-1/4 items-center text-center px-1">
            <div className="font-medium">
              $
              {millify(Number(fee / 10 ** 18), {
                units: ["", "K", "M", "B", "T", "P", "E"],
                space: false,
              })}
            </div>

            <p className="text-[x-small]">Trading Fees</p>
          </div>
        </div>

        <div className="flex w-full items-center flex-col rate">
          <p className="title">check today’s USDC to ztoken exchange rates</p>
          <div className="flex w-10/12 bg-white p-2 justify-between items-center mt-2">
            <div className="w-full text-[14px] flex justify-center items-center">
              {" "}
              <img src={USDC} alt="USDC" width="20" className="mr-1" /> USDC
            </div>
            <div
              className="w-full flex text-[12px] border-l-2 justify-center items-center"
              onClick={() => setShowZAssets(!showZAssets)}
            >
              {currRate} {zToken}
              {showZAssets ? (
                <AiOutlineUp size={14} color={"#5A5A65"} className="mr-2" />
              ) : (
                <AiOutlineDown size={14} color={"#5A5A65"} className="mr-2" />
              )}
            </div>
            {showZAssets && (
              <div
                className="text-font-grey cursor-pointer absolute rounded  select-assets"
                style={{
                  marginLeft: 0,
                }}
              >
                <div
                  className="flex p-2 mb-2  hover:bg-green-200 duration-300"
                  onClick={() => {
                    setCurrRate(NGNUSD);
                    setZToken("zNGN");
                    setShowZAssets(false);
                  }}
                >
                  <img src={ZNGN} alt="" className="h-7" />
                  <p className="ml-2">zNGN</p>
                </div>
                <div
                  className="flex p-2 mb-2  hover:bg-green-200 duration-300"
                  onClick={() => {
                    setCurrRate(XAFUSD);
                    setZToken("zXAF");
                    setShowZAssets(false);
                  }}
                >
                  <img src={ZXAF} alt="" className="h-7" />
                  <p className="ml-2">zXAF</p>
                </div>
                <div
                  className="flex p-2 mb-2  hover:bg-green-200 duration-300"
                  onClick={() => {
                    setCurrRate(ZARUSD);
                    setZToken("zZAR");
                    setShowZAssets(false);
                  }}
                >
                  <img src={ZZAR} alt="" className="h-7" />
                  <p className="ml-2">zZAR</p>
                </div>
              </div>
            )}
          </div>
          <p className="text-[#626262] text-[12px] mt-2">
            *As of Today, {time.getDate()} {months[`${time.getMonth() + 1}`]},{" "}
            {formattedTime} UTC
          </p>

          <div className="flex text-sm gap-2 w-full px-3 !mt-12">
            <Link to="/mint" className="basis-1/2">
              <button className="text-white py-2 text-xs w-full bg-[#241F17] rounded-lg px-3 h-fit hover:bg-opacity-75 transition duration-500">
                <p className="my-auto pb-[0.2rem]">Go to App</p>
              </button>
            </Link>
            <a
              href="https://docs.baki.exchange"
              target="_blank"
              className="basis-1/2"
              rel="noreferrer"
            >
              <button className="text-xs border py-2 w-full border-black inline-flex place-content-center rounded-lg px-3 h-fit hover:bg-[#241F17] hover:text-white transition duration-500">
                <p className="my-auto p-0">Learn More </p>
                <span>
                  <RiArrowRightUpLine className="h-5 p-0" />
                </span>
              </button>
            </a>
          </div>
        </div>
        {/* <div className="flex flex-col justify-center items-center">
        <img src={baki} alt="" className="h-8 w-24" />
        <p className="title">
          Infinite Liquidity FX Exchange for African Currencies
        </p>
        <p className="sub-title">
          Baki provides the ability to offer infinite liquidity at the official
          conversion rate, and natively quote assets in local currencies on
          chain.
        </p>
        <div className="flex w-full justify-center mt-5 mb-6">
          <div className="">
            <div className="font-medium">
              $
              {millify(totalCollateral / 10 ** 18, {
                units: ["", "K", "M", "B", "T", "P", "E"],
                space: false,
              })}
            </div>

            <p className="text-[x-small]">Global Collateral</p>
          </div>
          <div className="p-2 flex justify-center items-center flex-col home-detail">
            <div className="text-[14px]  font-bold">
              {" "}
              $
              {millify(globalDebt / 10 ** 18, {
                units: ["", "K", "M", "B", "T", "P", "E"],
                space: false,
              })}
            </div>

            <p className="text-[x-small]">zToken Market Cap</p>
          </div>
          <div className="p-2 flex justify-center items-center flex-col home-detail">
            <div className="font-medium">
              $
              {millify(Number(totalVolume / 10 ** 18), {
                units: ["", "K", "M", "B", "T", "P", "E"],
                space: false,
              })}
            </div>

            <p className="text-[x-small]">Trading Volume</p>
          </div>
          <div className="p-2 flex justify-center items-center flex-col home-last-item">
            <div className="font-medium">
              $
              {millify(Number(fee / 10 ** 18), {
                units: ["", "K", "M", "B", "T", "P", "E"],
                space: false,
              })}
            </div>

            <p className="text-[x-small]">Trading Fees</p>
          </div>
        </div>
        <div className="flex w-full items-center flex-col rate">
          <p className="title">check today’s USDC to ztoken exchange rates</p>
          <div className="flex w-10/12 bg-white p-2 justify-between items-center mt-2">
            <div className="w-full text-[14px] flex justify-center items-center">
              {" "}
              <img src={USDC} alt="USDC" width="20" className="mr-1" /> USDC
            </div>
            <div
              className="w-full flex text-[12px] border-l-2 justify-center items-center"
              onClick={() => setShowZAssets(!showZAssets)}
            >
              {currRate} {zToken}
              {showZAssets ? (
                <AiOutlineUp size={14} color={"#5A5A65"} className="mr-2" />
              ) : (
                <AiOutlineDown size={14} color={"#5A5A65"} className="mr-2" />
              )}
            </div>
            {showZAssets && (
              <div
                className="text-font-grey cursor-pointer absolute rounded  select-assets"
                style={{
                  marginLeft: 0,
                }}
              >
                <div
                  className="flex p-2 mb-2  hover:bg-green-200 duration-300"
                  onClick={() => {
                    setCurrRate(NGNUSD);
                    setZToken("zNGN");
                    setShowZAssets(false);
                  }}
                >
                  <img src={ZNGN} alt="" className="h-7" />
                  <p className="ml-2">zNGN</p>
                </div>
                <div
                  className="flex p-2 mb-2  hover:bg-green-200 duration-300"
                  onClick={() => {
                    setCurrRate(XAFUSD);
                    setZToken("zXAF");
                    setShowZAssets(false);
                  }}
                >
                  <img src={ZXAF} alt="" className="h-7" />
                  <p className="ml-2">zXAF</p>
                </div>
                <div
                  className="flex p-2 mb-2  hover:bg-green-200 duration-300"
                  onClick={() => {
                    setCurrRate(ZARUSD);
                    setZToken("zZAR");
                    setShowZAssets(false);
                  }}
                >
                  <img src={ZZAR} alt="" className="h-7" />
                  <p className="ml-2">zZAR</p>
                </div>
              </div>
            )}
          </div>
          <p className="text-[#626262] text-[12px] mt-2">
            *As of Today, {time.getDate()} {months[`${time.getMonth() + 1}`]},{" "}
            {formattedTime} UTC
          </p>
          <div className="flex text-sm gap-2 !mt-12">
            <Link to="/mint">
              <button className="text-white text-xs bg-[#241F17] rounded-lg px-3 py-1 h-fit hover:bg-opacity-75 transition duration-500">
                <p className="my-auto pb-[0.2rem]">Go to App</p>
              </button>
            </Link>
            <a href="https://docs.baki.exchange" target="_blank">
              <button className="text-xs border border-black inline-flex rounded-lg px-3 py-0.5 h-fit hover:bg-[#241F17] hover:text-white transition duration-500">
                <p className="my-auto">Learn More </p>
                <RiArrowRightUpLine className="h-4 w-auto my-[0.15rem]" />
              </button>
            </a>
          </div>
        </div>
      </div> */}
      </div>
    </div>
  );
};

export default Home;
