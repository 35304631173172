/* eslint-disable react-hooks/exhaustive-deps */
import { ethers } from "ethers";
import {  useEffect, useState } from "react";
import useConnector from "./useConnector";
// import { config } from "../config";
import USDC from "../contracts/USDC.json";
import zToken from "../contracts/zToken.json";
import { useAccount } from "wagmi";
import useConfigs from "./useConfigs";
import { toast } from "react-toastify";
import { notifySlack } from "../utils";

function useToken(asset: string, zAsset: boolean) {
  const config: any = useConfigs()
  const { provider } = useConnector();
  const { address } = useAccount();
  const [contract, setContract] = useState<any>(null);
  const [decimals, setDecimal] = useState<number>(18);
  const [allowance, setAllowance] = useState<any>(null);


   
  useEffect(() => {
    if (provider && asset) {
      const signer = provider.getSigner();
      if (zAsset) {
        setContract(new ethers.Contract(String(config[asset]), zToken, signer));
      } else {
        if (asset === "USDC") {
          setContract(new ethers.Contract(String(config[asset]), USDC, signer));
        }
      }
    }
  }, [provider, asset]);

  useEffect(() => {
       contract?.decimals().then((decimals:any) => setDecimal(decimals))
  }, [contract]);


  useEffect(() => {
   if(contract){
    checkAllowance();
   }
  });

  const approve = async (_depositAmount: number) => {
    try {
      const tx = await contract.approve(
        config?.vaultAddress,
        ethers.utils.parseUnits(String(_depositAmount), decimals),
        {
          gasLimit: 100000,
        }
      );
      await tx.wait();
      toast.success("Transaction approved !!")
      return true
    } catch (error) {
      console.error(error);
      toast.error(String(error))
      notifySlack(error, 'useToken.approve', {amount: _depositAmount}, address)
      return false
    }
  };

  const checkAllowance = async () => {
    const _allowance = await contract?.allowance(address, config?.vaultAddress);    
    setAllowance(Number(ethers.utils.formatUnits(_allowance, decimals)));
  };
  return { approve, allowance, decimals, contract };
}

export default useToken;
