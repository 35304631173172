import React from "react";
import baki from "../../../assets/baki.png";
import "./Splash.css";
import { TailSpin } from "react-loader-spinner";

const Splash = () => {
  return (
    <div className="splash">
      <div className="flex flex-col h-full justify-center items-center">
        <div className="flex flex-1 flex-col justify-center items-center">
          <img src={baki} alt="" className="mb-6" height="30" width="135" />
          <TailSpin
            height="80"
            width="40"
            color="#f56530"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
        <p className="pb-6">v 1.1.0</p>
      </div>
    </div>
  );
};

export default Splash;
