/* eslint-disable @typescript-eslint/no-unused-vars */
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState } from "react";
import Home from "./pages/Home/Home";
import Swap from "./pages/App/Swap/Swap";
import Mint from "./pages/App/Mint/Mint";
import Transactions from "./pages/App/Transactions/Transactions";
import Liquidation from "./pages/App/Liquidation/Liquidation";
// Mobile
import MobileHome from "./pages/Mobile/Home/MobileHome";
import MobileMint from "./pages/Mobile/Mint/Mint";
import MobileSwap from "./pages/Mobile/Swap/Swap";
import MobileLiquidation from "./pages/Mobile/Liquidation/Liquidation";
import MobileLiquidationDetail from "./pages/Mobile/Liquidation/LiquidationDetail";
import MobileTransactions from "./pages/Mobile/Transactions/Transactions";
import MobileTransfer from "./pages/Mobile/Transfer/Transfer";

import Error from "./pages/Error/Error";
import { ToastContainer } from "react-toastify";
import Page404 from "./pages/Page404/Page404";
import AppContext, { AppContextValue } from "./context";
import { useChainId, useNetwork } from "wagmi";
import { useMediaQuery } from "react-responsive";
import Transfer from "./pages/App/Transfer/Transfer";
import TourContext from "./TourGuideContext";
import EOA from "./pages/EOA";

declare const window: any;

(async () => {
  let isMainnet = await window.localStorage.getItem("isMainnet");
  if (!isMainnet) {
    await window.localStorage.clear();
    await window.localStorage.setItem("isMainnet", true);
  }
})();

function App() {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 600 });
  const { chain }: any = useNetwork();
  const [showGuide, setShowGuide] = useState<boolean>(false);
  const [showGuideCompleted, setShowGuideCompleted] = useState<boolean>(false);
  const [tourLoading, setTourLoading] = useState<boolean>(false);
  const [tourRunning, setTourRunning] = useState<boolean>(false);

  const data: AppContextValue = {
    chainId: +chain?.id,
  };

  return (
    <AppContext.Provider value={data}>
      <TourContext.Provider
        value={{
          showGuide,
          setShowGuide,
          showGuideCompleted,
          setShowGuideCompleted,
          tourLoading,
          setTourLoading,
          tourRunning,
          setTourRunning,
        }}
      >
        {isTabletOrMobile ? <MobileOrTablet /> : <Desktop />}
      </TourContext.Provider>
    </AppContext.Provider>
  );
}

const Desktop = () => (
  <Router>
    <Routes>
      {/* <Route path="/" element={<Maintainanace />} /> */}
      <Route path="/" element={<Home />} />
      <Route path="/mint" element={<Mint />} />
      <Route path="/swap" element={<Swap />} />
      <Route path="/liquidation" element={<Liquidation />} />
      <Route path="/transactions" element={<Transactions />} />
      <Route path="/transfer" element={<Transfer />} />
      <Route path="/eoa/:address" element={<EOA />} />
      <Route path="/error" element={<Error />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
    <ToastContainer />
  </Router>
);

const MobileOrTablet = () => (
  <Router>
    <Routes>
      {/* <Route path="/" element={<Maintainanace />} /> */}
      <Route path="/" element={<MobileHome />} />
      <Route path="/mint" element={<MobileMint />} />
      <Route path="/swap" element={<MobileSwap />} />
      <Route path="/liquidation" element={<MobileLiquidation />} />
      <Route path="/transactions" element={<MobileTransactions />} />
      <Route path="/transfer" element={<MobileTransfer />} />
      <Route path="/eoa/:address" element={<EOA />} />
      <Route path="/error" element={<Error />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
    <ToastContainer />
  </Router>
);

export default App;
