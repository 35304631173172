/* eslint-disable @typescript-eslint/no-unused-vars */
// import "./Repay.css";
import React, { useEffect, useState } from "react";
import ZUSD from "../../assets/ZUSD.png";
import USDC from "../../assets/usdc.png";
import { toast } from "react-toastify";
import useDeposit from "../../hooks/useDeposit";
import { useSelector } from "react-redux";
import useToken from "../../hooks/useToken";
import useWithdraw from "../../hooks/useWithdraw";
import { PiSpinnerBold } from "react-icons/pi";
import { TiArrowDownOutline } from "react-icons/ti";
import { removeLastNonZeroDigit } from "../../helper";

interface RepayProps {
  rewardBal: any;
  handleClaimReward: any;
  claimLoading: Boolean;
}

function Repay({ rewardBal, handleClaimReward, claimLoading }: RepayProps) {
  const { zUSDBal, userColBalance, userDebt } = useSelector(
    (state: any) => state.baki
  );
  const [zTokenAmount, setZTokenAmount] = useState<any>(0);
  const [colAmount, setColAmount] = useState<any>(0);
  const [btnTitle, setBtnTitle] = useState<"Withdraw" | "Repay">("Withdraw");
  const [zAsset] = useState<string>("zUSD");
  const [_approve, setApprove] = useState<boolean>(false);
  const [debt, setDebt] = useState<any>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const { approve, allowance } = useToken("zUSD", true);
  const { withdraw } = useWithdraw();

  const handleApprove = async () => {
    if (zTokenAmount <= 0) return;
    setLoading(true);
    let result = await approve(zTokenAmount);
    if (result) {
      setLoading(false);
      setApprove(true);
    } else {
      setLoading(false);
    }
  };

  const repay = async () => {
    if (colAmount < 0 || zTokenAmount < 0) return;
    setLoading(true);
    const result = await withdraw(zTokenAmount, colAmount, zAsset, zAsset);
    if (result) {
      setColAmount(0);
      toast.success("Transaction Successful !!");
      setLoading(false);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      toast.error(result);
      setLoading(false);
    }
  };

  useEffect(() => {
    setDebt(userDebt);
  }, [userDebt]);

  useEffect(() => {
    if (zTokenAmount > allowance) {
      setApprove(false);
    } else {
      setApprove(true);
    }
  }, [zTokenAmount, allowance]);

  useEffect(() => {
    if (zTokenAmount > 0 && colAmount <= 0) {
      setBtnTitle("Repay");
    } else {
      setBtnTitle("Withdraw");
    }
  }, [zTokenAmount, colAmount, btnTitle]);

  return (
    <div className="gap-8 p-3 space-y-3 bg-[#FFFAF3] border-thin border-gray-300 rounded-xl">
      <div className="claim-rewards w-full rounded-xl border-thin border-gray-400 px-3 md:px-8 py-3 flex justify-between">
        <div className="my-auto w-fit">
          <p className="text-xs">UNCLAIMED REWARDS</p>
          <span className="font-semibold text-xl">
            {rewardBal?.toLocaleString(undefined, {
              maximumFractionDigits: 5,
            })}
            <span className="">zUSD</span>
          </span>
        </div>
        {/* <div className=""> */}
        <button
          className=" my-auto text-white bg-[#241F17] md:rounded-3xl rounded-md px-3 py-2 align-middle disabled:bg-opacity-75"
          onClick={handleClaimReward}
          disabled={rewardBal < 0}
        >
          {claimLoading ? (
            <PiSpinnerBold className="h-4 w-4 animate-spin" />
          ) : (
            <>
              <p className="block md:hidden text-xs p-0 my-auto">Claim Now</p>
              <p className="hidden md:block text-xs p-0 my-auto">Claim</p>
            </>
          )}
        </button>
      </div>

      <p className="text-center text-xs py-2">
        Select the asset you would like to withdraw.
      </p>

      <div>
        <div className="flex justify-between text-[x-small] items-center w-full">
          <p className="">REPAY ZUSD</p>
          <p className="" onClick={() => removeLastNonZeroDigit(zUSDBal)}>
            zUSD balance:{" "}
            {zUSDBal?.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
          </p>
        </div>

        <div className="inline-flex bg-white mint-field rounded-md p-2 mb-2 text-xs w-full border-thin border-gray-400">
          <div className="flex items-center w-1/2 border-r-thin border-gray-400">
            <img src={ZUSD} alt="zUSD" className="h-5" />
            <span className="ml-1 text-font-grey">zUSD </span>
          </div>
          <input
            type="number"
            placeholder="Enter Amount"
            value={zTokenAmount ? zTokenAmount : ""}
            onChange={(e) => setZTokenAmount(e.target.value)}
            className="w-1/2 px-2 text-right focus:outline-none "
          />
        </div>
      </div>
      <div className="mt-12">
        <div className="relative w-full my-2 border-thin  border-gray-300">
          <div className=" absolute my-auto mx-auto rounded-full bg-[#FEE7C0] p-1 w-6 h-6 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <TiArrowDownOutline className="h-full w-auto text-[#D6A45B] " />
          </div>
        </div>
      </div>

      <div>
        <div className="flex justify-between items-center text-[x-small] w-full">
          <p>WITHDRAW COLLATERAL</p>
          <p
            onClick={() => setColAmount(removeLastNonZeroDigit(userColBalance))}
          >
            USDC balance:{" "}
            {userColBalance?.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
          </p>
        </div>

        <div className="inline-flex bg-white mint-field rounded-md p-2 mb-3 text-xs w-full border-thin border-gray-400">
          <div className="flex items-center w-1/2 border-r-thin border-gray-400">
            <img src={USDC} alt="USDC" className="h-5" />
            <span className="ml-1 text-font-grey text-[x-small]">USDC </span>
          </div>
          <input
            value={colAmount ? colAmount : ""}
            type="number"
            placeholder="Enter Amount"
            onChange={(e) => setColAmount(e.target.value)}
            className="w-1/2 px-2 text-right focus:outline-none"
          />
        </div>
      </div>

      <button
        onClick={() => (!_approve ? handleApprove() : repay())}
        className={`w-full ${
          !_approve ? "bg-[#FB7F37]" : "bg-[#241F17]"
        }  text-white rounded text-xs p-2 flex justify-center items-center disabled:bg-opacity-75`}
        disabled={loading}
      >
        {!_approve ? (
          <>
            {loading ? (
              <PiSpinnerBold className="h-4 w-4 animate-spin" />
            ) : (
              "Approve"
            )}
          </>
        ) : (
          <>
            {loading ? (
              <PiSpinnerBold className="h-4 w-4 animate-spin" />
            ) : (
              btnTitle
            )}
          </>
        )}
      </button>
    </div>
  );
}

export default Repay;
