/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-empty-pattern */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import useData from "../../../hooks/useData";
import Splash from "../../../components/Mobile/SplashScreen/Splash";
import MobileHome from "../../../components/Mobile/Home/Home";
import { ethers } from "ethers";
import useHome from "../../../hooks/useHome";

function Home() {
  const _ = useData();

  const [splashLoad, setSplashLoad] = useState<boolean>(true);
  const [rateLoading, setRateLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);

  const { contract, oracle } = useHome();
  const [totalVolume, setTotalVolume] = useState<number>(0);
  const [totalCollateral, setTotalCollateral] = useState<number>(0);
  const [globalDebt, setGlobalDebt] = useState<number>(0);
  const [fee, setFee] = useState<number>(0);
  const [NGNUSD, setNGNUSD] = useState<number>(0);
  const [XAFUSD, setXAFUSD] = useState<number>(0);
  const [ZARUSD, setZARUSD] = useState<number>(0);

  useEffect(() => {
    if (oracle) {
      oracle?.getZTokenUSDValue("zngn").then((value: any) => {
        setNGNUSD(Number(value?._hex) / 1000);
        setRateLoading(false);
      });
      oracle?.getZTokenUSDValue("zxaf").then((value: any) => {
        setXAFUSD(Number(value?._hex) / 1000);
      });
      oracle?.getZTokenUSDValue("zzar").then((value: any) => {
        setZARUSD(Number(value?._hex) / 1000);
        setRateLoading(false);
      });
    }
  }, [oracle]);

  useEffect(() => {
    if (contract) {
      contract
        ?.globalMintersFee()
        .then((result: number) => {
          setFee(parseInt(ethers.utils.formatUnits(result, 0)));
          setLoading(false);
        })
        .catch((error: any) => {
          setLoading(false);
          console.log(error);
        });
      contract
        ?.totalSwapVolume()
        .then((result: number) => {
          setLoading(false);
          setTotalVolume(parseInt(ethers.utils.formatUnits(result, 0)));
        })
        .catch((error: any) => {
          setLoading(false);
          console.log(error);
        });

      contract
        ?.totalCollateral()
        .then((result: number) => {
          setLoading(false);
          setTotalCollateral(parseInt(ethers.utils.formatUnits(result, 0)));
        })
        .catch((error: any) => {
          setLoading(false);
          console.log(error);
        });

      contract
        ?.getGlobalDebt()
        .then((result: number) => {
          setLoading(false);
          setGlobalDebt(parseInt(ethers.utils.formatUnits(result, 0)));
        })
        .catch((error: any) => {
          setLoading(false);
          console.log(error);
        });
    }
  }, [contract]);

  useEffect(() => {
    if (!loading && !rateLoading) {
      setSplashLoad(false);
    }
  }, [loading, rateLoading]);

  return (
    <>
      {splashLoad ? (
        <Splash />
      ) : (
        <MobileHome
          loading={loading}
          rateLoading={rateLoading}
          fee={fee}
          totalVolume={totalVolume}
          globalDebt={globalDebt}
          NGNUSD={NGNUSD}
          XAFUSD={XAFUSD}
          ZARUSD={ZARUSD}
          totalCollateral={totalCollateral}
        />
      )}
    </>
  );
}

export default Home;
