/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-empty-pattern */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import millify from "millify";
import useData from "../../hooks/useData";
import { ethers } from "ethers";
import useHome from "../../hooks/useHome";
import { InfinitySpin } from "react-loader-spinner";
import baki from "../../assets/baki.png";
import paladin from "../../assets/paladin.png";
import { RiArrowRightUpLine } from "react-icons/ri";

function Home() {
  const _ = useData();
  const { contract, oracle } = useHome();
  const [totalVolume, setTotalVolume] = useState<number>(0);
  const [totalCollateral, setTotalCollateral] = useState<number>(0);
  const [globalDebt, setGlobalDebt] = useState<number>(0);
  const [fee, setFee] = useState<number>(0);
  const [NGNUSD, setNGNUSD] = useState<number>(0);
  const [XAFUSD, setXAFUSD] = useState<number>(0);
  const [ZARUSD, setZARUSD] = useState<number>(0);
  const [rateLoading, setRateLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (oracle) {
      oracle?.getZTokenUSDValue("zngn").then((value: any) => {
        setNGNUSD(Number(value?._hex) / 1000);
        setRateLoading(false);
      });
      oracle?.getZTokenUSDValue("zxaf").then((value: any) => {
        setXAFUSD(Number(value?._hex) / 1000);
      });
      oracle?.getZTokenUSDValue("zzar").then((value: any) => {
        setZARUSD(Number(value?._hex) / 1000);
        setRateLoading(false);
      });
    }
  }, [oracle]);

  useEffect(() => {
    if (contract) {
      contract
        ?.globalMintersFee()
        .then((result: number) => {
          setFee(parseInt(ethers.utils.formatUnits(result, 0)));
          setLoading(false);
        })
        .catch((error: any) => {
          setLoading(false);
          console.error(error);
        });
      contract
        ?.totalSwapVolume()
        .then((result: number) => {
          setLoading(false);
          console.log(parseInt(ethers.utils.formatUnits(result, 0)));

          setTotalVolume(parseInt(ethers.utils.formatUnits(result, 0)));
        })
        .catch((error: any) => {
          setLoading(false);
          console.log(error);
        });

      contract
        ?.totalCollateral()
        .then((result: number) => {
          setLoading(false);
          setTotalCollateral(parseInt(ethers.utils.formatUnits(result, 0)));
        })
        .catch((error: any) => {
          setLoading(false);
          // console.log(error);
        });

      contract
        ?.getGlobalDebt()
        .then((result: number) => {
          setLoading(false);
          setGlobalDebt(parseInt(ethers.utils.formatUnits(result, 0)));
        })
        .catch((error: any) => {
          setLoading(false);
          // console.log(error);
        });
    }
  }, [contract]);

  return (
    <div className=" bg-home">
      <div className="inline-flex">
        {rateLoading ? (
          <div className="w-screen h-6 loading-skeleton"></div>
        ) : (
          <>
            <div className="gap-9 partner-slide w-screen grid grid-flow-col bg-[#7D6535] py-1 text-white text-xs">
              <div className="flex justify-center items-center">
                <img src="/images/zngn.png" className="h-4 w-4" alt="" />
                <p className="ml-2"> {NGNUSD.toFixed(2)} zNGN</p>
              </div>
              <div className="rounded-full h-1 w-1 bg-white my-auto"></div>
              <div className="flex justify-center items-center">
                <img src="/images/zxaf.png" className="h-4 w-4" alt="" />
                <p className="ml-2">{XAFUSD.toFixed(2)} zXAF</p>
              </div>
              <div className="rounded-full h-1 w-1 bg-white my-auto"></div>
              <div className="flex justify-center items-center">
                <img src="/images/zzar.png" className="h-4 w-4" alt="" />
                <p className="ml-2">{ZARUSD.toFixed(2)} zZAR</p>
              </div>
              <div className="rounded-full h-1 w-1 bg-white my-auto"></div>
              <p className="rate-text">Today’s zUSD to zToken exchange rates</p>
              <div className="rounded-full h-1 w-1 bg-white my-auto"></div>
            </div>
            <div className="gap-9 partner-slide w-screen grid grid-flow-col bg-[#7D6535] py-1 text-white text-xs">
              <div className="flex justify-center items-center">
                <img src="/images/zngn.png" className="h-4 w-4" alt="" />
                <p className="ml-2"> {NGNUSD.toFixed(2)} zNGN</p>
              </div>
              <div className="rounded-full h-1 w-1 bg-white my-auto"></div>
              <div className="flex justify-center items-center">
                <img src="/images/zxaf.png" className="h-4 w-4" alt="" />
                <p className="ml-2">{XAFUSD.toFixed(2)} zXAF</p>
              </div>
              <div className="rounded-full h-1 w-1 bg-white my-auto"></div>
              <div className="flex justify-center items-center">
                <img src="/images/zzar.png" className="h-4 w-4" alt="" />
                <p className="ml-2">{ZARUSD.toFixed(2)} zZAR</p>
              </div>
              <div className="rounded-full h-1 w-1 bg-white my-auto"></div>
              <p className="rate-text">Today’s zUSD to zToken exchange rates</p>
              <div className="rounded-full h-1 w-1 bg-white my-auto"></div>
            </div>
          </>
        )}
      </div>
      <div className="w-full text-[#454647] bg-home h-screen">
        <div className="flex flex-col flex-1 space-y-3 w-full h-full items-center">
          <br />
          <br />
          <img src={baki} alt="" className="h-17 w-40" />
          <div className="space-y-9 text-center px-4 mt-4">
            <p className="text-5xl font-semibold">
              Infinite Liquidity FX Exchange for <br /> African Currencies
            </p>
            <p className="text-sm">
              Baki provides the ability to offer infinite liquidity at the
              official <br /> conversion rate, and natively quote assets in
              local currencies on chain.
            </p>
            <div className="!mt-6">
              <p className="text-[x-small]">CERTIFIED AND AUDITED BY</p>
              <img src={paladin} alt="" className="h-10 w-18 mx-auto" />
            </div>
          </div>

          {loading ? (
            <>
              <div className="w-1/3 h-6 loading-skeleton opacity-30 rounded-md"></div>
              <div className="w-1/4 h-3 loading-skeleton opacity-30 rounded-md"></div>
            </>
          ) : (
            <div className="flex w-3/5 justify-center divide-x divide-gray-400 !mt-6">
              <div className=" flex justify-center items-center basis-1/5 flex-col">
                <div className="font-medium">
                  $
                  {millify(totalCollateral / 10 ** 18, {
                    units: ["", "K", "M", "B", "T", "P", "E"],
                    space: false,
                  })}
                </div>

                <p className="text-xs">Global Collateral</p>
              </div>
              <div className=" flex justify-center items-center basis-1/5 flex-col">
                <div className="font-medium">
                  {" "}
                  $
                  {millify(globalDebt / 10 ** 18, {
                    units: ["", "K", "M", "B", "T", "P", "E"],
                    space: false,
                  })}
                </div>
                <p className="text-xs">zToken Market Cap</p>
              </div>
              <div className=" flex justify-center items-center basis-1/5 flex-col">
                <div className="font-medium">
                  $
                  {millify(Number(totalVolume / 10 ** 18), {
                    units: ["", "K", "M", "B", "T", "P", "E"],
                    space: false,
                  })}
                </div>
                <p className="text-xs">Trading Volume</p>
              </div>
              <div className=" flex justify-center items-center basis-1/5 flex-col">
                <div className="font-medium">
                  $
                  {millify(Number(fee / 10 ** 18), {
                    units: ["", "K", "M", "B", "T", "P", "E"],
                    space: false,
                  })}
                </div>
                <p className="text-xs">Trading Fees</p>
              </div>
            </div>
          )}

          <div className="flex text-sm gap-2 !mt-12">
            <Link to="/mint">
              <button className="text-white text-xs bg-[#241F17] rounded-lg px-6 py-2 h-fit hover:bg-opacity-75 transition duration-500">
                <p className="my-auto pb-[0.2rem]">Go to App</p>
              </button>
            </Link>
            <a href="https://docs.baki.exchange" target="_blank">
              <button className="text-xs border border-black inline-flex rounded-lg px-3 py-1.5 h-fit hover:bg-[#241F17] hover:text-white transition duration-500">
                <p className="my-auto">Learn More </p>
                <RiArrowRightUpLine className="h-4 w-auto my-[0.15rem]" />
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
