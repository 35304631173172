import axios from "axios";

export const getUser = async (userAddress: string) => {
  // Make a GET request to the Airtable API with the specified parameters using Axios
  return axios
    .get(
      `https://ujdlpye8h6.execute-api.eu-west-2.amazonaws.com/eoa/${userAddress}`
    )
    .then((response) => {
      // Handle the data received from Airtable
      return response.data;
    })
    .catch((error) => {
      // Handle any errors that occur during the request
      console.error("Error fetching data:", error);
    });
};

export const registerUser = async (userAddress: string) => {
  try {
    const response = await axios.post(
      "https://ujdlpye8h6.execute-api.eu-west-2.amazonaws.com/eoa",
      {
        address: userAddress,
      }
    );
    console.log(response.data);
  } catch (error) {
    console.error("Error:", error);
  }
};
