/* eslint-disable @typescript-eslint/no-unused-vars */
import { ethers } from "ethers";
import useSigner from "./useSigner";
import { updateTransactions } from "../redux/reducers/bakiReducer";
import { useDispatch, useSelector } from "react-redux";
import { useNetwork } from "wagmi";
import { toast } from "react-toastify";
import transactionBody from "./transactionBody";
// import { notifySlack } from "../utils";

declare const window: any;

function useWithdraw() {
  const dispatch = useDispatch();
  const { address } = useSelector((state: any) => state.baki);
  const { chain }: any = useNetwork();

  const { contract } = useSigner();

  const withdraw = async (
    _amountToRepay: number,
    _amountToWithdraw: number,
    _zToken: string,
    _asset: string
  ) => {
    try {
      let transactions: any = {};
      let _transactions = [];
      let transaction = transactionBody(chain);
      let date = new Date();
      transaction.date = `${date.getDate()}/${
        date.getMonth() + 1
      }/${date.getFullYear()}`;
      let ztoken = _zToken === "ZCFA" ? "zxaf" : _zToken?.toLowerCase();
      console.log(
        "Value:",
        String(ethers.utils.parseUnits(String(_amountToRepay), "ether"))
      );

      const tx = await contract.repayAndWithdraw(
        ethers.utils.parseUnits(String(_amountToRepay), "ether"),
        ethers.utils.parseUnits(String(_amountToWithdraw), "ether"),
        ztoken
      );
      await tx.wait();
      const txns = await window.localStorage.getItem("transactions");
      transaction.repayBody.repayAmount = Number(_amountToRepay);
      transaction.repayBody.withdrawAmount = Number(_amountToWithdraw);
      transaction.repayBody.repayCurrency = _asset;
      transaction.action = "Withdraw";
      transaction.status = "Successful";
      transaction.hash = tx?.hash;

      if (txns) {
        if (JSON.parse(txns)[address]?.length <= 5) {
          _transactions = JSON.parse(txns)[address];
          _transactions.push(transaction);
        } else {
          _transactions.push(transaction);
        }
      } else {
        _transactions.push(transaction);
      }

      transactions[address] = _transactions.reverse();

      await window.localStorage.setItem(
        "transactions",
        JSON.stringify(transactions)
      );
      dispatch(updateTransactions(transactions));
      return true;
    } catch (err: any) {
      let transactions: any = {};
      let _transactions = [];
      let transaction = transactionBody(chain);
      let date = new Date();
      transaction.date = `${date.getDate()}/${
        date.getMonth() + 1
      }/${date.getFullYear()}`;
      transaction.repayBody.repayAmount = Number(_amountToRepay);
      transaction.repayBody.withdrawAmount = Number(_amountToWithdraw);
      transaction.action = "Withdraw";
      transaction.status = "Failed";
      transaction.hash = "";
      const txns = await window.localStorage.getItem("transactions");

      if (txns) {
        if (JSON.parse(txns)[address]?.length <= 5) {
          _transactions = JSON.parse(txns)[address];
          _transactions.push(transaction);
        } else {
          _transactions.push(transaction);
        }
      } else {
        _transactions.push(transaction);
      }

      transactions[address] = _transactions.reverse();

      await window.localStorage.setItem(
        "transactions",
        JSON.stringify(transactions)
      );
      console.error(err);
      toast.error(String(err));
      // notifySlack(err, 'useWithdraw.withdraw', {amountToRepay: _amountToRepay,
      //   amountToWithdraw: _amountToWithdraw,
      //   zToken: _zToken,
      //   asset:_asset
      // }, address)
      return false;
    }
  };
  return { withdraw };
}

export default useWithdraw;
