/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import MainLayout from "../../../layouts/Desktop/MainLayout";
import SwapCard from "../../../components/Swap/SwapCard";

import useToken from "../../../hooks/useToken";
import useSwap from "../../../hooks/useSwap";
import useOracle from "../../../hooks/useOracle";
import axios from "axios";
import { config } from "../../../config";
import Transactions from "../../../components/Home/Transactions/Transactions";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import useData from "../../../hooks/useData";
import { removeLastNonZeroDigit } from "../../../helper";

axios.defaults.headers.common["apikey"] = config.exchangeRatesAPIKEY;
function Swap() {
  const [fromAmount, setFromAmount] = useState<any>(0);
  const [toAmount, setToAmount] = useState<any>(0);
  const _ = useData();
  const [showFromZAssets, setShowFromZAssets] = useState<boolean>(false);
  const [showToZAssets, setShowToZAssets] = useState<boolean>(false);
  const [fromZAsset, setFromZAsset] = useState<string>("zUSD");
  const [toZAsset, setToZAsset] = useState<string>("zNGN");
  const [_approve, setApprove] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [rate, setRate] = useState<number>(0);
  const [swapOutput, setSwapOutput] = useState<number>(0);
  const { approve, allowance } = useToken(fromZAsset, true);
  const { swap } = useSwap();
  const [showQuote, setShowQuote] = useState<boolean>(false);
  const {
    getNGNUSD,
    getXAFUSD,
    getZARUSD,
    getCOLUSD,
    getNGNXAF,
    getZARXAF,
    getNGNZAR,
  } = useOracle();

  const { zUSDBal, zNGNBal, zXAFBal, zZARBal, fee } = useSelector(
    (state: any) => state.baki
  );

  const getRates = async () => {
    try {
      let NGNUSDRate = await getNGNUSD();
      let XAFUSDRate = await getXAFUSD();
      let ZARUSDRate = await getZARUSD();
      let COLUSDRate = await getCOLUSD();
      let NGNXAFRate = await getNGNXAF();
      let ZARXAFRate = await getZARXAF();
      let NGNZARRate = await getNGNZAR();

      return {
        NGN: NGNUSDRate,
        XAF: XAFUSDRate,
        ZAR: ZARUSDRate,
        USD: COLUSDRate,
        NGNXAF: 1 / NGNXAFRate,
        ZARXAF: 1 / ZARXAFRate,
        NGNZAR: 1 / NGNZARRate,
        XAFNGN: NGNXAFRate,
        XAFZAR: ZARXAFRate,
        ZARNGN: NGNZARRate,
      };
    } catch (error) {
      console.error(error);
    }
  };

  const handleApprove = async () => {
    if (loading) return;
    if (fromZAsset === toZAsset) return;
    if (!_approve) {
      setLoading(true);
      let result = await approve(fromAmount);
      if (result) {
        setApprove(true);
      }
      setLoading(false);
      setShowQuote(false);
    }
  };

  const handleSwap = async () => {
    if (loading) return;

    if (fromZAsset === toZAsset) return;
    if (fromAmount && toAmount && _approve) {
      setLoading(true);
      const result = await swap(fromAmount, fromZAsset, toZAsset, swapOutput);

      if (result) {
        setLoading(false);
        toast.success("Transaction Successful !!");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        setLoading(false);
      }
    }
  };

  const handleFields = (_value: string, _field: string) => {
    if (_field === "To") {
      setToAmount(Number(_value));
      setFromAmount(Number(_value) / rate);
    } else if (_field === "From") {
      setFromAmount(Number(_value));
      setToAmount(Number(_value) * rate);
    }
  };

  useEffect(() => {
    if (fromAmount > allowance) {
      setApprove(false);
    } else {
      setApprove(true);
    }
  }, [fromAmount, toAmount, allowance]);

  useEffect(() => {
    if (!fromAmount && !toAmount && !rate) {
      getRates().then((result: any) => {
        setRate(result?.[`${toZAsset.substring(1)}`]);
      });
    }
  }, [getRates]);

  useEffect(() => {
    if (fromAmount) {
      setLoading(true);
      setLoading(true);
      getRates()
        .then((result: any) => {
          if (fromZAsset.substring(1) === "USD") {
            setRate(result?.[`${toZAsset.substring(1)}`]);
            const output = result?.[`${toZAsset.substring(1)}`] * fromAmount;
            setSwapOutput(output);
            setToAmount(output);
          }

          if (toZAsset.substring(1) === "USD") {
            setRate(1 / result?.[`${fromZAsset.substring(1)}`]);
            const output =
              (1 / result?.[`${fromZAsset.substring(1)}`]) * fromAmount;
            setSwapOutput(output);
            setToAmount(output);
          }

          if (
            fromZAsset.substring(1) !== "USD" &&
            toZAsset.substring(1) !== "USD"
          ) {
            setRate(
              result?.[`${fromZAsset.substring(1)}${toZAsset.substring(1)}`]
            );
            const output =
              result?.[`${fromZAsset.substring(1)}${toZAsset.substring(1)}`] *
              fromAmount;
            setSwapOutput(output);
            setToAmount(output);
          }
          if (fromZAsset.substring(1) === toZAsset.substring(1)) {
            setRate(1);
            const output = 1 * fromAmount;
            setSwapOutput(output);
            setToAmount(output);
          }

          setLoading(false);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setLoading(false);
        });
    } else {
      getRates().then((res: any) => {
        if (fromZAsset.substring(1) === toZAsset.substring(1)) {
          return setRate(1);
        }

        if (toZAsset.substring(1) === "USD") {
          return setRate(1 / res?.[`${fromZAsset.substring(1)}`]);
        }

        if (fromZAsset.substring(1) === "USD") {
          return setRate(res?.[`${toZAsset.substring(1)}`]);
        }

        if (
          toZAsset.substring(1) !== "USD" &&
          fromZAsset.substring(1) !== "USD"
        ) {
          return setRate(
            res?.[`${fromZAsset.substring(1)}${toZAsset.substring(1)}`]
          );
        }
      });
    }
  }, [fromAmount, rate, toZAsset, fromZAsset]);

  // SETQUOTE USEEFFECT
  useEffect(() => {
    if (fromAmount > 0 || toAmount > 0) {
      setShowQuote(true);
    }
  }, [fromAmount, toAmount]);

  const selectFromZAsset = (_asset: string) => {
    setFromZAsset(_asset);
    setShowFromZAssets(false);
  };

  const selectToZAsset = (_asset: string) => {
    setToZAsset(_asset);
    setShowToZAssets(false);
  };

  return (
    <>
      <MainLayout>
        <br className="hidden md:block" />
        <div className="w-full h-full max-w-md lg:max-w-xl xl:max-w-3xl mx-auto grid pt-10 justify-center transition-all duration-700">
          <SwapCard
            fromZAsset={fromZAsset}
            toZAsset={toZAsset}
            rate={rate}
            fee={fee}
            swapOutput={swapOutput}
            fromAmount={fromAmount}
            toAmount={toAmount}
            zUSDBal={zUSDBal}
            zZARBal={zZARBal}
            zXAFBal={zXAFBal}
            zNGNBal={zNGNBal}
            showFromZAssets={showFromZAssets}
            showToZAssets={showToZAssets}
            showQuote={showQuote}
            setShowToZAssets={setShowToZAssets}
            selectFromZAsset={selectFromZAsset}
            setShowFromZAssets={setShowFromZAssets}
            selectToZAsset={selectToZAsset}
            handleFields={handleFields}
            setToAmount={setToAmount}
            handleApprove={handleApprove}
            handleSwap={handleSwap}
            setFromAmount={setFromAmount}
            _approve={_approve}
            loading={loading}
          />
        </div>
      </MainLayout>
    </>
  );
}

export default Swap;
