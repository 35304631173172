/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
// import { config } from "../config";
import vaultAbi from "../contracts/vault.json";
import oracleABI from "../contracts/oracle.json";

import { ethers } from "ethers";
import useConfigs from "./useConfigs";
import useConnector from "./useConnector";


const useProvider = () => {
  const config = useConfigs()
  const [contract, setContract] = useState<any>(null);
  const [oracle, setOracle] = useState<any>(null);
  const {provider} = useConnector()

  useEffect(() => {
    if (provider) {
      const signer = provider.getSigner();
      setContract(new ethers.Contract(String(config?.vaultAddress), vaultAbi, signer));
      setOracle(new ethers.Contract(String(config?.oracleAddress), oracleABI, signer))
    }
  }, [provider]);
  

  return { contract, oracle };
};

export default useProvider;
