import { useMediaQuery } from "react-responsive";
import baki from "../../assets/baki.png";
import useConnector from "../../hooks/useConnector";

import "./Error.css";
const Error = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
  const { switchNetwork } = useConnector();
  return (
    <div className="error flex w-8/10">
      <img
        src={baki}
        alt=""
        height={isTabletOrMobile ? "15" : "26"}
        width={isTabletOrMobile ? "100" : "200"}
      />

      {isTabletOrMobile ? (
        <p className="text-[20px] text-center font-bold-200">
          Infinite Liquidity FX Exchange for African Currencies
        </p>
      ) : (
        <p className="title">
          Infinite Liquidity FX Exchange for African Currencies
        </p>
      )}

      <img src="/images/network.png" alt="" height="26" width="200" />

      {isTabletOrMobile ? (
        <p className="text-center text-[12px]">
          {" "}
          Sorry! Baki is currently not yet deployed to this network. Please
          switch to Avalanche C-Chain
        </p>
      ) : (
        <p className="sub-title">
          Sorry! Baki is currently not yet deployed to this network. Please
          switch to Avalanche C-Chain
        </p>
      )}
      {isTabletOrMobile ? (
        <button
          className="text-[10px] bg-black text-white p-2 rounded mt-4"
          onClick={() => switchNetwork(43114)}
        >
          <p>Switch Network</p>
        </button>
      ) : (
        <button className="d-button" onClick={() => switchNetwork(43114)}>
          <p>Switch Network</p>
        </button>
      )}
    </div>
  );
};

export default Error;
