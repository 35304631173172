import { createContext } from "react";

interface TourType {
  showGuide: boolean;
  setShowGuide: React.Dispatch<React.SetStateAction<boolean>>;
  showGuideCompleted: boolean;
  setShowGuideCompleted: React.Dispatch<React.SetStateAction<boolean>>;
  tourLoading: boolean;
  setTourLoading: React.Dispatch<React.SetStateAction<boolean>>;
  tourRunning: boolean;
  setTourRunning: React.Dispatch<React.SetStateAction<boolean>>;
}

const TourContext = createContext<TourType>({
  showGuide: true,
  setShowGuide: () => {},
  showGuideCompleted: true,
  setShowGuideCompleted: () => {},
  tourLoading: false,
  setTourLoading: () => {},
  tourRunning: false,
  setTourRunning: () => {},
});

export default TourContext;
