import React from "react";

export default function LoadingTable() {
  const numbers = Array.from({ length: 10 }, (_, index) => index);

  return (
    <div className="w-full space-y-2 p-2">
      {numbers.map((_, index) => (
        <div key={index} className="inline-flex w-full gap-2">
          <div className="h-5 rounded-md w-full bg-gray-200 skeleton-loading"></div>
          <div className="h-5 rounded-md w-full bg-gray-200 skeleton-loading"></div>
          <div className="h-5 rounded-md w-full bg-gray-200 skeleton-loading"></div>
          <div className="h-5 rounded-md w-full bg-gray-200 skeleton-loading"></div>
        </div>
      ))}
    </div>
  );
}
