import React from "react";

// Define the type for the context value
export type AppContextValue = {
  chainId: number;
};

// Create the context with the specified type
const AppContext = React.createContext<AppContextValue | undefined>(undefined);

export default AppContext;
