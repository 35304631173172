export const removeLastNonZeroDigit = (number: number) => {
  let numberStr = number.toString();

  if (numberStr.indexOf(".") === -1) {
    console.log(number);
    return number;
  }

  let lastNonZeroIndex = -1;
  for (let i = numberStr.length - 1; i >= 0; i--) {
    if (numberStr[i] !== "0") {
      lastNonZeroIndex = i;
      break;
    }
  }

  if (lastNonZeroIndex === -1) {
    console.log(number);
    return number;
  }

  let modifiednumberStr =
    numberStr.slice(0, lastNonZeroIndex) +
    numberStr.slice(lastNonZeroIndex + 1);
  console.log(modifiednumberStr);
  return parseFloat(modifiednumberStr);
};
