/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { ethers } from "ethers";
import { useDispatch } from "react-redux";
import { updateAddress } from "../redux/reducers/bakiReducer";
import { useNavigate } from "react-router-dom";
import { useNetwork, useWalletClient, useSwitchNetwork } from "wagmi";
import { notifySlack } from "../utils";
import {config} from "../config"
import AppContext from "../context";
import { toast } from "react-toastify";

declare const window: any;

function useConnector() {
  const { chain }: any = useNetwork()
  const { switchNetwork : changeNetwork } =
    useSwitchNetwork()
  const context = useContext(AppContext)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data } = useWalletClient();

  const [provider, setProvider] = useState<any>(null);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("chainChanged", () => {
        window.location.replace("/mint");
      });
      window.ethereum.on("accountsChanged", () => {
        window.location.reload();
      });
    }
  }, [context?.chainId]);




  useEffect(() => {
    if (data) {
      // Set Provider
      setProvider(new ethers.providers.Web3Provider(data as any));
    }
  }, [data]);


  useEffect(() => {
    if(provider){
       // check Connection
     checkConnection();
    }
  }, [provider])

  useEffect(() => {
    // Check Network
    if(context?.chainId){
      checkNetwork();
    }
  }, [context?.chainId]);




  const checkNetwork = async () => {
    try {
      
      if (+chain?.id !== 43113 && +chain?.id !== 43114){
       return navigate("/error");
      } 
     
      // dispatch(updateNetwork(network));
    } catch (error) {
      console.log(error);
    }
  };

  const connectWallet = async () => {
    provider
      .send("eth_requestAccounts")
      .then(async (result: Array<string>) => {
        localStorage.setItem("baki_user", result[0]);
        dispatch(updateAddress(result[0]));
      })
      .catch((error: any) => {
        console.error(error);
        notifySlack(error, 'Connect Wallet', {})
      });
  };

  function checkConnection() {
      provider.provider.request({method: "eth_accounts"})
      .then(handleAccountsChanged)
      .catch(console.error);
  }
  
  function handleAccountsChanged(accounts: any) {
    if (accounts.length === 0 && window.location.pathname !== "/") {
      dispatch(updateAddress(""));
    } else {
      dispatch(updateAddress(accounts[0]));
      checkNetwork();
    }
  }

  const switchNetwork = async (chainId:number) => {
    try {
      // await window.ethereum.request({
      //   method: "wallet_switchEthereumChain",
      //   params: [{ chainId:  `0x${Number(chainId).toString(16)}` }],
      // });
      changeNetwork?.(Number(chainId));
    } catch {
      window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: `0x${Number(43114).toString(16)}`,
            rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
            chainName: "Avalanche C-Chain",
            nativeCurrency: {
              name: "AVAX",
              symbol: "AVAX",
              decimals: 18,
            },
            blockExplorerUrls: ["https://snowtrace.io"],
          },
          {
            chainId:  `0x${Number(43113).toString(16)}`,
            rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
            chainName: "Avalanche Fuji Testnet",
            nativeCurrency: {
              name: "AVAX",
              symbol: "AVAX",
              decimals: 18,
            },
            blockExplorerUrls: ["https://testnet.snowtrace.io"],
          },
        ],
      });
    }
  };


const addTokens = async () => {
  const tokens = [
    {
      address: config.zUSD,
      symbol: 'zUSD',
      decimals: 18
    },
    {
      address: config.zXAF,
      symbol: 'zXAF',
      decimals: 18
    },
    {
      address: config.zZAR,
      symbol: 'zZAR',
      decimals: 18
    },
    {
      address: config.zNGN,
      symbol: 'zNGN',
      decimals: 18
    }
  ];
  
  // Request access to the user's MetaMask account
  if (provider){
    try {
     for(let token of tokens) {
        await provider.provider.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: token.address,
              symbol: token.symbol,
              decimals: token.decimals
            }
          }
        });
        
        console.log(`${token.symbol} added to MetaMask!`);
      }
    } catch (error) {
      toast.error('Error adding tokens to MetaMask');
    }
  } else {
    toast.error('MetaMask is not installed!');
  }
}

  return {
    connectWallet,
    switchNetwork,
    provider,
    addTokens
  };
}

export default useConnector;