import axios from "axios";
import { config } from "./config";

const NOTIFY_URL=config.notifyUrl;

export const notifySlack = async (err: any, funcName: string, extras: any, address?: string) => {

    let errorDetails = typeof extras === 'object' ? extras : {};

    if (typeof err === 'object') {
        if (err.message.includes('rejected')) return;
        errorDetails = {
            name: err.name,
            ...errorDetails,
            message: err.message,
            stack: err.stack,
            timestamp: new Date()
        }
    } else {
        errorDetails = {
            ...errorDetails,
            message: err.toString(),
            timestamp: new Date()
        }
    }

    const payload = {
        appName: 'Baki Client',
        functionName: funcName,
        userAddress: address,
        isEndUser: true,
        errorDetails
    }

    try {

        await axios.post(NOTIFY_URL, payload);

    } catch (e) {
        console.error(e)
    }

}