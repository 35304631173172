/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import "./Mint.css";
import MobileLayout from "../../../layouts/Mobile/MobileLayout";
import useData from "../../../hooks/useData";
import useDeposit from "../../../hooks/useDeposit";
import { useSelector } from "react-redux";
import loader from "../../../assets/loader/loader.gif";
import { toast } from "react-toastify";
// import Deposit from "../../../components/Mobile/Deposit/Deposit";
// import Withdraw from "../../../components/Mobile/Withdraw/Withdraw";
import { TbAntennaBars4 } from "react-icons/tb";
import { PiCurrencyCircleDollar, PiCurrencyDollar } from "react-icons/pi";
import { GoClock } from "react-icons/go";
import MintCard from "../../../components/Mint/MintCard";
import TourContext from "../../../TourGuideContext";
import { Link } from "react-router-dom";

const Mint = () => {
  const _ = useData();

  const [action, setAction] = useState<string>("deposit");
  const [value, setValue] = useState<number>();
  const { getUSDValue, deposit } = useDeposit();
  const { userColBalance, userDebt, network, rewardBal } = useSelector(
    (state: any) => state.baki
  );
  const [loading, setLoading] = useState<boolean>(false);
  const { setShowGuide, tourRunning } = useContext(TourContext);

  const handleClaimReward = async () => {
    if (rewardBal / 10 ** -6 <= 0) return;
    setLoading(true);
    let result = await deposit(0, rewardBal);
    if (result) {
      toast.success("Transaction Successful !!");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUSDValue(userColBalance).then((_value) => {
      setValue(_value);
    });
  }, [value, getUSDValue, userColBalance]);

  useEffect(() => {
    const delayTour = setTimeout(() => {
      if (localStorage.getItem("tour-completed") === null && !tourRunning) {
        setShowGuide(true);
      }
    }, 1500);
    return () => clearTimeout(delayTour);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MobileLayout>
      <div className="flex flex-col w-full py-3">
        <div className="w-full px-6 py-2">
          <p className="m-overview-text">OVERVIEW</p>
        </div>
        <div className="m-user-details px-6">
          <div className="m-user-detail p-3">
            <div className=" space-y-2 ">
              <p className="heading ">C RATIO</p>
              <div className="flex itams-center ">
                <p className="detail-subt pr-2">
                  {userDebt === 0
                    ? "0"
                    : ((userColBalance / userDebt) * 100).toFixed(2)}{" "}
                  %
                </p>
                <div
                  className="indicator"
                  style={{
                    backgroundColor:
                      userColBalance > 0
                        ? userColBalance / userDebt >= 1.5
                          ? "green"
                          : "red"
                        : "grey",
                    marginTop: 5,
                  }}
                ></div>
              </div>
            </div>
            <TbAntennaBars4 className="w-[17px] h-[17px]" />
          </div>
          <div className="m-user-detail p-3">
            <div>
              <p className="heading">Collateral</p>
              <p className="detail-subt  mt-2">
                {Number(Number(userColBalance).toFixed(2))?.toLocaleString(
                  undefined,
                  {
                    maximumFractionDigits: 2,
                  }
                )}{" "}
                USDC
              </p>
            </div>
            <PiCurrencyCircleDollar className="w-[17px] h-[17px]" />
          </div>
          <div className="m-user-detail p-3">
            <div>
              <p className="heading">Value</p>
              <p className="detail-subt  mt-2">
                $
                {Number(value?.toFixed(2))?.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}
              </p>
            </div>
            <PiCurrencyDollar className="w-[17px] h-[17px]" />
          </div>
          <div className="m-user-detail p-3">
            <div>
              <p className="heading">Debt</p>
              <p className="detail-subt  mt-2">
                {Number(Number(userDebt).toFixed(2))?.toLocaleString(
                  undefined,
                  {
                    maximumFractionDigits: 2,
                  }
                )}
              </p>
            </div>

            <GoClock className="w-[17px] h-[17px]" />
          </div>
        </div>
        <Link to="/transactions" className="px-6 ml-2">
          <p className="m-transactions-text">View Transactions</p>
        </Link>
        <MintCard
          userColBalance={userColBalance}
          userDebt={userDebt}
          value={value}
          handleClaimReward={handleClaimReward}
          rewardBal={rewardBal}
          loading={loading}
        />
      </div>
    </MobileLayout>
  );
};

export default Mint;
