import React, { useEffect, useState } from "react";
import { getUser } from "../airtable/index";
import { useParams } from "react-router-dom";

interface IData {
  exists: boolean;
  connected: boolean;
}
const EOA = () => {
  const { address } = useParams();
  const [data, setData] = useState<IData>({
    exists: false,
    connected: false,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (address) {
      setLoading(true);
      getUser(String(address)).then((_data) => {
        setData(_data);
      });
      setLoading(false);
    }
  }, [address]);

  return (
    <div className="p-6">
      {loading ? (
        <div className="p-6">
          <p>Loading...</p>
        </div>
      ) : (
        <div>
          <h2 className="text-h1 text-bold mb-2">Baki Account Details</h2>
          <div className="p-6 border rounded">
            <div>Address: {address}</div>
            <div>Exists: {String(data?.exists)}</div>
            <div>Connected: {String(data?.exists)}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EOA;
