/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import { updateTransactions } from "../redux/reducers/bakiReducer";
import useToken from "./useToken";
import { useNetwork } from "wagmi";
import { useDispatch, useSelector } from "react-redux";
import transactionBody from "./transactionBody";

function useTransfer(asset: string, _amount: number, _address: string) {
  const { approve, contract } = useToken(asset, true);
  const { address } = useSelector((state: any) => state.baki);
  const [transferApprove, setApprove] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { chain }: any = useNetwork();

  const transfer = async () => {
    let hash = "";
    try {

      let result = await approve(_amount);
      setApprove(true)
      if (result) {
        const tx = await contract.transfer(
          _address,
          ethers.utils.parseUnits(String(_amount), "ether")
        );
        await tx.wait();

        let transactions: any = {};
        let _transactions = [];
        let transaction = transactionBody(chain);
        let date = new Date();
        transaction.date = `${date.getDate()}/${
          date.getMonth() + 1
        }/${date.getFullYear()}`;
        const txns = window.localStorage.getItem("transactions");
        transaction.transferBody.amount = Number(_amount);
        transaction.transferBody.recipient = _address;
        transaction.transferBody.currency = asset;
        transaction.action = "Transfer";
        transaction.status = "Successful";
        transaction.hash = tx?.hash;
        hash = tx?.hash;
        if (txns) {
          if (JSON.parse(txns)[address]?.length <= 10) {
            _transactions = JSON.parse(txns)[address];
            _transactions.push(transaction);
          } else {
            _transactions.push(transaction);
          }
        } else {
          _transactions.push(transaction);
        }

        transactions[address] = _transactions.reverse();

        window.localStorage.setItem(
          "transactions",
          JSON.stringify(transactions)
        );
        dispatch(updateTransactions(transactions));
      }
      return true;
    } catch (e) {
      console.error(e);
      let transactions: any = {};
      let _transactions = [];
      let transaction = transactionBody(chain);
      let date = new Date();
      transaction.date = `${date.getDate()}/${
        date.getMonth() + 1
      }/${date.getFullYear()}`;
      const txns = window.localStorage.getItem("transactions");
      transaction.transferBody.amount = Number(_amount);
      transaction.transferBody.recipient = _address;
      transaction.transferBody.currency = asset;
      transaction.action = "Transfer";
      transaction.status = "Failed";
      transaction.hash = hash;
      if (txns) {
        if (JSON.parse(txns)[address]?.length <= 10) {
          _transactions = JSON.parse(txns)[address];
          _transactions.push(transaction);
        } else {
          _transactions.push(transaction);
        }
      } else {
        _transactions.push(transaction);
      }

      transactions[address] = _transactions.reverse();

      window.localStorage.setItem("transactions", JSON.stringify(transactions));
      dispatch(updateTransactions(transactions));
      toast.error(String(e));
    }
  };

  return { transfer, transferApprove };
}

export default useTransfer;
