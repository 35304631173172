import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { WagmiConfig } from "wagmi";
import { avalanche, avalancheFuji } from "wagmi/chains";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";

const chains = [avalanche, avalancheFuji];

// 1. Get projectId
const projectId = "8a7193d214047981ef8a61048c0538c6";

// 2. Create wagmiConfig
const metadata = {
  name: "Baki",
  description:
    "Baki is an infinite liquidity FX exchange, built on @CeloOrg providing the first on-chain implementation of African stable coins.",
  url: "https://baki.exchange",
  icons: ["https://canzassets.s3.amazonaws.com/logo.png"],
};

const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });

// 3. Create modal
createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  featuredWalletIds: [
    "8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4",
    "cbc11415130d01316513f735eac34fd1ad7a5d40a993bbb6772d2c02eeef3df8",
    "ad2eff108bf828a39e5cb41331d95861c9cc516aede9cb6a95d75d98c206e204",
  ],
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// Detect OKx
const ua = navigator.userAgent;
const isIOS = /iphone|ipad|ipod|ios/i.test(ua);
const isAndroid = /android|XiaoMi|MiuiBrowser/i.test(ua);
const isMobile = isIOS || isAndroid;
const isOKApp = /OKApp/i.test(ua);

if (isMobile && !isOKApp) {
  // open dapp in okx App
  window.open("okx://wallet/dapp/details?dappUrl=https://baki.exchange");
}

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <WagmiConfig config={wagmiConfig}>
        <App />
      </WagmiConfig>
    </Provider>
  </React.StrictMode>
);

serviceWorkerRegistration.unregister();

reportWebVitals();
