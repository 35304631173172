import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// axios.defaults.headers.common["apikey"] = config.exchangeRatesAPIKEY;
export interface State {
  openBids: number[];
  totalLiquidation: number[];
}

const initialState: State = {
  openBids: [],
  totalLiquidation: [],
};

export const alaSlice = createSlice({
  name: "ALA",
  initialState,
  reducers: {
    updateBids: (state, action: PayloadAction<number>) => {
      state.openBids.push(action.payload);
    },
    updateLiquidations: (state, action: PayloadAction<number>) => {
      state.totalLiquidation.push(action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateBids, updateLiquidations } = alaSlice.actions;

export default alaSlice.reducer;
