import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import "./TourGuide.css";
import { FaCheck } from "react-icons/fa";

interface TourGuideProp {
  isOpen: boolean;
  setIsOpen: any;
}

export default function GuideOutro({ isOpen, setIsOpen }: TourGuideProp) {
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/75" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-md bg-white text-left align-middle shadow-xl transition-all waves-bg">
                  <div className="place-content-center grid grid-flow-row my-auto p-4 lg:py-12 lg:w-2/3 mx-auto text-center space-y-4">
                    <figure className="rounded-full p-3 bg-[#FEE7C0] max-w-fit mx-auto">
                      <FaCheck className="h-5 w-5 text-[#D6A45B]" />
                    </figure>
                    <p className="text:2xl lg:text-3xl leading font-semibold">
                      Tour Completed 🎉
                    </p>
                    <p className="text-sm lg:text-base">
                      Congratulations on finishing your tour of the Baki web
                      app!
                    </p>
                    <p className="text-sm lg:text-base">
                      You can now try the features out for yourself and get
                      started on achieving some quick wins.
                    </p>
                    <p className="text-sm lg:text-base">Happy Trading!</p>

                    <button
                      className="py-2 px-4 bg-black text-sm lg:text-base text-white max-w-fit rounded-lg mx-auto hover:scale-105 duration-500"
                      onClick={() => {
                        if (!localStorage.getItem("tour-completed")) {
                          localStorage.setItem("tour-completed", "true");
                        }
                        setIsOpen(false);
                      }}
                    >
                      Back to Dashboard
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
