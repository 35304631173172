/* eslint-disable react/jsx-no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState, useContext, useEffect } from "react";
import TourContext from "../../TourGuideContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./MainLayout.css";
import { useMediaQuery } from "react-responsive";
import { BiMenu, BiLeftArrowAlt } from "react-icons/bi";
import Notifications from "../../components/Notifications/Notifications";
import { useAccount, useChainId } from "wagmi";
import useConnector from "../../hooks/useConnector";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { BsArrowRepeat } from "react-icons/bs";
import { IoArrowRedoOutline } from "react-icons/io5";
import { GoChevronUp } from "react-icons/go";
import { LuArrowUpRightFromCircle } from "react-icons/lu";
import { RxDiscordLogo } from "react-icons/rx";
import {
  PiTelegramLogo,
  PiStudent,
  PiHeadsetLight,
  PiCurrencyDollar,
  PiLayout,
} from "react-icons/pi";

import { TfiImport } from "react-icons/tfi";
import { BsBell } from "react-icons/bs";
import useTourGuide from "../../hooks/useTourGuide";
import GuideIntro from "../../components/TourGuide/GuideIntro";
import GuideOutro from "../../components/TourGuide/GuideOutro";
import { registerUser } from "../../airtable";

interface MainLayoutProps {
  children: React.ReactNode;
}
const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const { switchNetwork, addTokens } = useConnector();
  const chain = useChainId();

  const { open } = useWeb3Modal();

  const [show, setShow] = useState<boolean>();
  const [route, setRoute] = useState<string>("home");
  const [viewNotifications, setViewNotifications] = useState<boolean>(false);
  const location: any = useLocation();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 818px)" });
  const [openSidebar, setOpenSidebar] = useState<string>("-300px");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { address, isConnected } = useAccount();
  const [openSupport, setOpenSupport] = useState(false);
  const { showGuide, setShowGuide, showGuideCompleted, setShowGuideCompleted } =
    useContext(TourContext);

  const { TourGuide } = useTourGuide();
  const navigate = useNavigate();

  const toggleSidebar = (_mode: boolean) => {
    setIsOpen(_mode);
    if (isOpen) {
      setOpenSidebar("0px");
    } else {
      setOpenSidebar("-300px");
    }
  };

  useEffect(() => {
    if (isConnected) registerUser(String(address));
  }, [isConnected, address]);

  return (
    <div className="flex h-full">
      <div
        style={{
          left: isTabletOrMobile ? openSidebar : 0,
        }}
        className={`w-1/6 h-full flex flex-col bg-lime-200 sidebar`}
      >
        <div className="border-b-thin h-14 border-white flex justify-center items-center">
          {isTabletOrMobile && (
            <button
              className="text-white flex mr-6"
              onClick={() => toggleSidebar(!isOpen)}
              type="button"
            >
              <div className="sr-only">button</div>
              <BiLeftArrowAlt size={24} color="white" />
            </button>
          )}
          {/* <w3m-button /> */}
          <Link to="/" className="w-5/12 mx-auto text-white">
            <img
              src="/images/baki-mark.png"
              className="h-5 w-auto"
              alt="baki logo"
            />
          </Link>
        </div>
        <div className="sidebar-bottom flex flex-col ps-2 pt-3 gap-3">
          <Link to="/mint">
            <div
              className={`position layout-route flex px-2 py-2 duration-700 ease-out text-sm ${
                location.pathname === "/mint" && "route-active"
              }`}
              onMouseEnter={() => setRoute("mint")}
              onMouseLeave={() => setRoute("")}
            >
              <PiLayout className="h-5 my-auto w-auto" />
              <p className="ml-1.5 ">My Position</p>
            </div>
          </Link>
          <Link to="/swap" className="swap">
            <div
              className={`layout-route flex px-2 py-2 duration-700 ease-out text-sm ${
                location.pathname === "/swap" && "route-active "
              }`}
              onMouseEnter={() => setRoute("swap")}
              onMouseLeave={() => setRoute("")}
            >
              <BsArrowRepeat className="my-auto h-5 w-auto" />
              <p className="ml-1.5 my-auto">Swap</p>
            </div>
          </Link>
          <Link to="/transfer" className="transfer">
            <div
              className={`layout-route flex px-2 py-2 duration-700 ease-out text-sm ${
                location.pathname === "/transfer" && "route-active"
              }`}
              onMouseEnter={() => setRoute("transfer")}
              onMouseLeave={() => setRoute("")}
            >
              <LuArrowUpRightFromCircle className="my-auto h-4 w-auto" />
              <p className="ml-1.5 ">Transfer</p>
            </div>
          </Link>
          <Link to="/liquidation">
            <div
              className={`layout-route flex px-2 py-2 duration-700 ease-out text-sm ${
                location.pathname === "/liquidation" && "route-active"
              }`}
              onMouseEnter={() => setRoute("liquidation")}
              onMouseLeave={() => setRoute("")}
            >
              <IoArrowRedoOutline className="my-auto h-5 w-auto" />
              <p className="ml-1.5 ">Liquidation</p>
            </div>
          </Link>

          <Link to="/transactions">
            <div
              className={`layout-route flex px-2 py-2 duration-700 ease-out text-sm ${
                location.pathname === "/transactions" && "route-active"
              }`}
              onMouseEnter={() => setRoute("transactions")}
              onMouseLeave={() => setRoute("")}
            >
              <PiCurrencyDollar
                className=" h-5 my-auto w-auto"
                color={
                  location.pathname === "/transactions" ||
                  route === "transactions"
                    ? "#000"
                    : "#fff"
                }
              />
              <p className="ml-1.5 ">Transactions</p>
            </div>
          </Link>

          <div
            // className="opacity-80"
            style={{
              boxSizing: "border-box",
              width: "80%",
              height: 1,
              marginLeft: "10%",
              borderTop: "0.1px solid #ccc",
              marginBottom: 10,
            }}
          ></div>

          <div
            className={`layout-route flex px-2 py-2 duration-700 ease-out text-sm hover:text-black`}
            onClick={() => setOpenSupport(!openSupport)}
          >
            <PiHeadsetLight className="h-5 my-auto w-auto" />
            <p className="ml-1.5 ">Support</p>
            <GoChevronUp
              className={`ms-auto me-2 h-5 my-auto w-auto ${
                !openSupport && "rotate-180"
              } duration-300 `}
            />
          </div>

          {openSupport && (
            <div className="transition-opacity mt-0 duration-300 flex flex-col gap-3 lg:gap-4 ease-in-out">
              <a
                href="https://discord.com/invite/JddhFeYUGB"
                target="_blank"
                rel="noreferrer noopener"
              >
                <div
                  className={`layout-route flex px-2 py-1 duration-700 ease-out text-smhover:text-black`}
                  onMouseEnter={() => setRoute("leave-app")}
                  onMouseLeave={() => setRoute("")}
                >
                  <RxDiscordLogo className="h-5 my-auto" />
                  <p className="ml-1.5 ">Discord</p>
                </div>
              </a>
              <a
                href="https://t.me/canzafinance"
                target="_blank"
                rel="noreferrer noopener"
              >
                <div
                  className={`layout-route flex px-2 py-1 duration-700 ease-out text-smhover:text-black`}
                  onMouseEnter={() => setRoute("leave-app")}
                  onMouseLeave={() => setRoute("")}
                >
                  <PiTelegramLogo className="h-5 my-auto" />
                  <p className="ml-1.5 ">Telegram</p>
                </div>
              </a>
              <button
                type="button"
                className={`layout-route w-full flex px-2 py-1 duration-700 ease-out text-smhover:text-black`}
                onClick={async () => {
                  await navigate("/mint");
                  setShowGuide(true);
                }}
              >
                <PiStudent className="h-5 my-auto" />
                <p className="ml-1.5 ">Learn Baki</p>
              </button>
            </div>
          )}

          <div onClick={addTokens} className="mt-2 m-0">
            <div
              className={`layout-route flex px-2 py-2 duration-700 ease-out text-sm hover:text-black`}
              onMouseEnter={() => setRoute("leave-app")}
              onMouseLeave={() => setRoute("")}
            >
              <TfiImport className=" h-4 my-auto w-auto" />
              <p className="ml-1.5 ">Import zTokens</p>
            </div>
          </div>
        </div>

        <div className=" py-4 flex justify-center items-center">
          <a href="/">
            <img
              src="/images/pb-avalanche.png"
              className="w-6/12 rounded-lg mx-auto"
              alt="powered by Avalanche"
            />
          </a>
        </div>
      </div>
      <div className="flex-1 h-full">
        <div className="flex justify-between px-3 h-14 items-center border-b-thin border-gray-400">
          {!isTabletOrMobile && (
            <div>
              <h1
                className="text-xl"
                style={{
                  marginTop: 0,
                }}
              >
                {location.pathname === "/mint" && "Mint"}
                {location.pathname === "/swap" && "Swap"}
                {location.pathname === "/liquidation" && "Liquidation"}
                {location.pathname === "/transactions" && "Transactions"}
                {location.pathname === "/transfer" && "Transfer"}
              </h1>
            </div>
          )}
          {isTabletOrMobile && (
            <button type="button" onClick={() => toggleSidebar(!isOpen)}>
              <div className="sr-only">toggleSidebar</div>
              <BiMenu size={24} />
            </button>
          )}
          <div className="flex justify-around items-center space-x-2">
            <button
              onClick={() => open()}
              style={{
                backgroundColor: "#FB7F37",
              }}
              className="connect rounded-xl text-xs px-4 py-1.5"
              type="button"
            >
              {address
                ? `${address?.slice(0, 5)} ... ${address?.slice(38, 42)}`
                : "Connect"}
            </button>
            {chain === 43113 && (
              <a
                href="http://faucet.0vb.xyz"
                className=""
                target="_blank"
                rel="noreferrer noopener"
              >
                <button className="rounded-md border-thin border-gray-400 px-3 text-sm py-1 hover:bg-[#fdf3e474] duration-300">
                  Get Test USDC
                </button>
              </a>
            )}
            {address && (
              <button
                type="button"
                onClick={() => setShow(!show)}
                className=" relative inline-flex rounded-md border-thin border-gray-400 px-3 text-sm py-1 hover:bg-[#fdf3e474] duration-300"
              >
                <img
                  src={
                    chain === 43114
                      ? "/images/avax.png"
                      : "/images/avax-dark.png"
                  }
                  className="h-5 w-5"
                  alt=""
                />
                {chain === 43113 && (
                  <p className="my-auto align-middle text-xs">Fuji Testnet</p>
                )}
                {chain === 43114 && (
                  <p className="ml-2 my-auto align-middle text-xs">
                    Avalanche C-Chain
                  </p>
                )}

                {show && (
                  <div className="absolute flex flex-column bg-black shadow-sm w-full inset-x-0 p-2 rounded text-white top-full mt-2">
                    <button
                      onClick={() => {
                        switchNetwork(43114);
                        setShow(false);
                      }}
                      className="flex p-1 rounded hover:bg-orange-700"
                    >
                      <img src="/images/avax.png" className="h-4" alt="" />
                      <p className="ml-2 my-auto align-middle text-xs">
                        Avalanche C-Chain
                      </p>
                    </button>
                    <button
                      onClick={() => {
                        switchNetwork(43113);
                        setShow(false);
                      }}
                      className="flex p-1 rounded mt-2  hover:bg-orange-700"
                    >
                      <img
                        src="/images/avax-white.png"
                        className="h-4"
                        alt=""
                      />
                      <p className="ml-2 my-auto align-middle text-xs">
                        Fuji Testnet
                      </p>
                    </button>
                  </div>
                )}
              </button>
            )}

            <div className="flex items-center">
              <button
                onClick={() => setViewNotifications(true)}
                className="m-0 p-0 relative"
                type="button"
              >
                <div className="sr-only">button</div>
                <span className="animate-ping absolute inline-flex top-0.5 right-1 h-1 w-1 rounded-full bg-orange-400 opacity-75"></span>
                <span className="absolute inline-flex rounded-full top-0.5 right-1 h-1 w-1 bg-orange-500"></span>
                <BsBell className="my-auto h-5 w-auto"></BsBell>
              </button>
            </div>
          </div>
        </div>
        <div className="layout-body">{children}</div>
      </div>

      <Notifications
        visible={viewNotifications}
        onClose={setViewNotifications}
      />

      <GuideIntro
        isOpen={showGuide}
        setIsOpen={setShowGuide}
        driver={TourGuide}
      />
      <GuideOutro
        isOpen={showGuideCompleted}
        setIsOpen={setShowGuideCompleted}
      />
    </div>
  );
};

export default MainLayout;
