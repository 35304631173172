/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ethers } from "ethers";
import { useEffect } from "react";
import useSigner from "./useSigner";
import { useDispatch } from "react-redux";
import {
  updateLiquidations,
  updateLiqLoading,
} from "../redux/reducers/bakiReducer";
import { notifySlack } from "../utils";

const useLiquidations = () => {
  const dispatch = useDispatch();
  const { contract } = useSigner();

  useEffect(() => {
    if (contract) {
      getLiquidations();
    }
  }, [contract]);

  const getLiquidations = async () => {
    try {
      dispatch(updateLiqLoading(true));

      const result: any[] = await contract?.manageUsersInLiquidationZone();

      let liquidations: Array<{
        address: string;
        reward: number;
        requiredZUSD: string | number;
      }> = [];

      for (let user of result) {
        let res = await contract?.getPotentialTotalReward(user);

        let required = await contract?.getUserDebt(user);

        let address = user;
        let reward = Number(res._hex) * 10 ** -18;
        let requiredZUSD = parseFloat(
          ethers.utils.formatUnits(required, 18)
        ).toFixed(2);
        liquidations = [
          ...liquidations,
          {
            address,
            reward,
            requiredZUSD,
          },
        ];
      }

      console.log(liquidations);
      

      dispatch(updateLiqLoading(false));
      dispatch(updateLiquidations(liquidations));
    } catch (error) {
      console.error(error);
      dispatch(updateLiqLoading(false));
    }
  };
  // const manageUsersLiquidation = async () => {
  //   try {
  //     const result = await contract?.manageUsersInLiquidationZone();
  //     dispatch(updateLiquidations(result));
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const _liquidate = async (address: string) => {
    try {
      const result = await contract?.liquidate(address);
      return true;
    } catch (error) {
      console.error(error);
      notifySlack(error, "useLiquidation._liquidate", {}, address);
      return false;
    }
  };

  return { _liquidate };
};

export default useLiquidations;
