/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNetwork } from "wagmi";
import EmptyTable from "../../../assets/emptytable.png";
import { BsArrowUpRightCircle } from "react-icons/bs";
import ReactPaginate from "react-paginate";

// import { fakeLQData, fakeLQData0 } from "../../../fakeData/data";

function Transactiions() {
  const { transactions, address } = useSelector((state: any) => state.baki);
  const { chain }: any = useNetwork();
  const [txns, setTxns] = useState([]);

  useEffect(() => {
    let _txns = transactions[address]?.filter(
      (tx: any) => tx.chain === chain?.id
    );
    setTxns(_txns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactions]);

  // PAGINATION VARIABLES
  const _arrayLength = txns?.length;
  const itemsPerPage = 10;
  const pageCount = Math.ceil(_arrayLength / itemsPerPage);

  var [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let isPreviousDisabled;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let isNextDisabled;

  const handlePageClick = (event: any) => {
    isPreviousDisabled = event.selected < 1;
    isNextDisabled = event.selected === pageCount - 1;
    var newOffset = (event.selected * itemsPerPage) % _arrayLength;
    setItemOffset(newOffset);
    return;
  };

  return (
    <div className="h-full relative pt-2 transition-all duration-700">
      <div className="w-full max-w-full p-4 h-full">
        <div className="relative w-full max-w-5xl mx-auto overflow-auto mb-11 flex justify-center items-center flex-col">
          <div className="w-full py-3 rounded-lg border-thin border-black">
            <p className="text-sm font-semibold leading-tight ps-3 pb-3">
              Recent Transactions
            </p>
            {/* {transaction.chian && transaction.chain === chain?.id && ( */}
            {txns?.length && (
              <table className="min-w-full leading-normal table-auto ">
                <thead className="">
                  <tr className="bg-[#F9F4ED] w-full text-left text-[x-small] text-gray-800 uppercase">
                    <th className="py-1 ps-3 font-semibold">TRANSACTION</th>
                    <th className="py-1 ps-3 font-semibold">STATUS</th>
                    <th className="py-1 ps-3 font-semibold">DATE</th>
                    <th className="py-1 ps-3 font-semibold"> ACTION </th>
                  </tr>
                </thead>
                {txns
                  ?.slice(itemOffset, endOffset)
                  .map((transaction: any, index: number) => (
                    <tbody className="text-[small]" key={index}>
                      <tr
                        className={
                          "hover:bg-gray-100 duration-500 ease-out text-xs h-[50px] py-5"
                        }
                      >
                        <td className="px-3 pt-2 pb-2 border-b border-gray-200 bg-transparent ">
                          {transaction.action === "Deposit" && (
                            <p>{`Deposited ${transaction.depositBody.colAmount?.toLocaleString(
                              undefined,
                              {
                                maximumFractionDigits: 2,
                              }
                            )} USDC and Minted ${transaction.depositBody.mintAmount?.toLocaleString(
                              undefined,
                              {
                                maximumFractionDigits: 2,
                              }
                            )} zUSD`}</p>
                          )}
                          {transaction.action === "Swap" && (
                            <p>{`Swap ${transaction.swapBody.fromAmount?.toLocaleString(
                              undefined,
                              {
                                maximumFractionDigits: 2,
                              }
                            )}  ${
                              transaction.swapBody.fromCurrency
                            } for ${transaction.swapBody.toAmount?.toLocaleString(
                              undefined,
                              {
                                maximumFractionDigits: 2,
                              }
                            )} ${transaction.swapBody.toCurrency}`}</p>
                          )}
                          {transaction.action === "Reward" && (
                            <p>{`${transaction.rewardBody.amount?.toLocaleString(
                              undefined,
                              {
                                maximumFractionDigits: 2,
                              }
                            )} zUSD  Claimed in Rewards`}</p>
                          )}
                          {transaction.action === "Transfer" && (
                            <p>{`Transfered ${transaction.transferBody.amount?.toLocaleString(
                              undefined,
                              {
                                maximumFractionDigits: 2,
                              }
                            )} ${
                              transaction.transferBody.currency
                            } to  ${transaction.transferBody.recipient.slice(
                              0,
                              10
                            )}`}</p>
                          )}
                          {transaction.action === "Withdraw" && (
                            <p>{`Repaid ${transaction.repayBody.repayAmount?.toLocaleString(
                              undefined,
                              {
                                maximumFractionDigits: 2,
                              }
                            )} ${
                              transaction.repayBody.repayCurrency
                            } and Withdrawn ${transaction.repayBody.withdrawAmount?.toLocaleString(
                              undefined,
                              {
                                maximumFractionDigits: 2,
                              }
                            )} USDC`}</p>
                          )}
                        </td>
                        <td className="px-3 pt-2 pb-2 border-b border-gray-200 bg-transparent ">
                          <p
                            className={
                              transaction.status === "Successful"
                                ? "text-green-700"
                                : "text-red-700"
                            }
                          >
                            {transaction.status}
                          </p>
                        </td>
                        <td className="px-3 pt-2 pb-2 border-b border-gray-200 bg-transparent">
                          <p>{transaction.date}</p>
                        </td>
                        <td className="px-3 gap-1 pt-2 pb-2 border-b border-gray-200 bg-transparent">
                          {transaction.hash && (
                            // <>
                            <a
                              className="inline-flex peer"
                              href={
                                transaction.hash
                                  ? `https://${
                                      chain?.id === 43114 ? "" : "testnet."
                                    }avascan.info/blockchain/c/tx/${
                                      transaction.hash
                                    }`
                                  : "/mint"
                              }
                              target={transaction.hash && "_blank"}
                            >
                              View Transaction
                              <BsArrowUpRightCircle className="ms-1 h-3 my-auto pt-0.5 peer-hover:scale=150 duration-300" />
                            </a>
                            // </>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  ))}
              </table>
            )}

            {!txns?.length && (
              <>
                <div className="w-full h-full grid grid-col place-items-center pt-10 space-y-4">
                  <img
                    src={EmptyTable}
                    alt=""
                    className="h-24 w-auto mx-auto"
                  />
                  <p className="text-xs text-[#454647] text-center">
                    You have no recent transactions!
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="h-auto px-4 mb-3 w-5/6 fixed bottom-0 mx-auto">
        <ReactPaginate
          breakLabel="..."
          previousLabel="⇽ Prev"
          nextLabel="Next ⇾"
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          pageRangeDisplayed={5}
          nextLinkClassName={`next`}
          previousLinkClassName={`prev`}
          disabledClassName="disable-nav"
          activeClassName="active-nav"
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  );
}

export default Transactiions;
