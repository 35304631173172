import { useNavigate } from "react-router-dom";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import { useContext } from "react";
import TourContext from "../TourGuideContext";

function useTourGuide() {
  const navigate = useNavigate();
  const {
    setShowGuideCompleted,
    setShowGuide,
    setTourRunning,
    setTourLoading,
  } = useContext(TourContext);
  const TourGuide = driver({
    showProgress: true,
    stagePadding: 2,
    steps: [
      {
        element: ".connect",
        popover: {
          title: "Connect wallet",
          description:
            "Once you’ve installed a wallet to your browser click the Connect button to log-in to Baki.",
          onNextClick: async () => {
            setTourRunning(true);
            TourGuide.moveNext();
          },
        },
      },
      {
        element: ".position",
        popover: {
          title: "My Position",
          description:
            "My position will allow you to manage your zUSD position by depositing, withdrawing, minting and  burning.",
        },
      },
      {
        element: ".mint-deposit",
        popover: {
          title: "Mind and Deposit Collateral",
          description:
            "To open a position, you first have to deposit an amount of USDC. This will become your collateral. You can now mint zUSD against that collateral.",
          onNextClick: async () => {
            await navigate("/swap");
            TourGuide.moveNext();
          },
        },
      },
      {
        element: ".swap",
        popover: {
          title: "Swap",
          side: "bottom",
          align: "start",
          description: "Swap will be where you can swap between zTokens.",
          onPrevClick: async () => {
            await navigate("/mint");
            setShowGuide(false);
            TourGuide.movePrevious();
          },
        },
      },
      {
        element: ".swap-ztokens",
        popover: {
          title: "Swap between zTokens",
          description:
            "To swap between tokens select your input currency and your output currency. Then type in the amount you would like to swap and the output currency is calculated for you. Once you are ready click swap to trigger the trade.",
          onNextClick: async () => {
            await navigate("/transfer");
            TourGuide.moveNext();
          },
        },
      },
      {
        element: ".transfer",
        popover: {
          title: "Transfer",
          description:
            "Transfer will enable you to send zTokens to other wallets",
          onPrevClick: async () => {
            navigate("/swap");
            TourGuide.moveNext();
          },
        },
      },
      {
        element: ".transfer-zToken",
        popover: {
          title: "Transfer",
          description:
            "To transfer zTokens to other wallets, You will need to select the zToken you want to transfer and the zToken you want the receiving wallet to receive. If they are not the same you will be requested to swap.",
          onNextClick: async () => {
            await navigate("/mint");
            TourGuide.moveNext();
          },
        },
      },
      {
        element: ".withdraw",
        popover: {
          title: "Withdraw",
          side: "bottom",
          align: "end",
          description:
            "To withdraw your collateral, you will need to repay your debt. Here you will be able to repay your zUSD and withdraw your USDC once you’ve done so.",
          onNextClick: async () => {
            if (!localStorage.getItem("tour-completed")) {
              localStorage.setItem("tour-completed", "true");
            }
            setShowGuideCompleted(true);
            setTourLoading(false);
            TourGuide.destroy();
            setTourRunning(false);
          },
        },
      },
    ],
    allowKeyboardControl: true,
    nextBtnText: "Next",
    prevBtnText: "Previous",
    doneBtnText: "Done",
    onPopoverRender(popover, opts) {
      setTimeout(() => {
        setTourLoading(false);
      }, 300);
    },
  });
  return { TourGuide };
}

export default useTourGuide;
