/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useConnector from "./useConnector";
import useSigner from "./useSigner";
import {
  updateGlobalNetMint,
  updateUserNetMint,
  updateTotalCollateral,
  updateUserDebt,
  updateGlobalDebt,
  updateTransactions,
  updateBalances,
  updateTotalVolume,
  updateFee,
  updateRewardBalance,
  updateUserColRatio,
  updateUserCollateral,
} from "../redux/reducers/bakiReducer";
import useConfigs from "./useConfigs";
import { ethers } from "ethers";
declare const window: any;
function useData() {
  const config = useConfigs();

  const dispatch = useDispatch();
  const { address, globalNetMint, userNetMint } = useSelector(
    (state: any) => state.baki
  );
  const { provider } = useConnector();

  const { contract } = useSigner();

  useEffect(() => {
    if (contract) {
      getUserDebt();
      getPosition();
      getTransactions();
      getzTokenBal();
    }
  }, [contract, address]);

  useEffect(() => {
    if (contract) {
      getGlobalDebt();
      getRewardBalance();
      getTransactions();
      getzTokenBal();
    }
  }, [provider, contract, userNetMint, globalNetMint, address]);

  // const getRates = async (base: string, target: string) => {
  //   try {
  //     const result = await axios.get(
  //       `https://api.apilayer.com/exchangerates_data/latest?symbols=${target}&base=${base}`
  //     );
  //     return result.data.rates;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const getPosition = async () => {

    // get user net mint
    const userNetMint = await contract?.getNetUserMintValue(address);
    dispatch(
      updateUserNetMint(Number(ethers.utils.formatUnits(userNetMint, 18)))
    );

    // get global net mint
    const globalNetMint = await contract?.getNetGlobalMintValue();
    dispatch(
      updateGlobalNetMint(Number(ethers.utils.formatUnits(globalNetMint, 18)))
    );
    // get total collateral
    const userCollateral: any = await contract?.getUserCollateralBalance(address);
    dispatch(
      updateUserCollateral(ethers.utils.formatUnits(userCollateral, 18))
    );

    // get totalVolume
    const totalVolume: any = await contract?.totalSwapVolume();
    dispatch(
      updateTotalVolume(Number(ethers.utils.formatUnits(totalVolume, 18)))
    );

    // get global Collateral
    const globalCol: any = await contract?.totalCollateral();
    dispatch(
      updateTotalCollateral(Number(ethers.utils.formatUnits(globalCol, 18)))
    );

    // get Col ratio
    const colRatio: any = await contract?.returnLastCollateralRatio(address);
    dispatch(updateUserColRatio(Number(colRatio?._hex) / 10));
  };

  const getzTokenBal = async () => {
    let zUSD = await contract?.getBalance(config?.zUSD);
    let zNGN = await contract?.getBalance(config?.zNGN);

    let zCFA = await contract?.getBalance(config?.zXAF);
    let zZAR = await contract?.getBalance(config?.zZAR);
    let ballances = {
      zUSD: Number(ethers.utils.formatUnits(zUSD, 18)),
      zNGN: Number(ethers.utils.formatUnits(zNGN, 18)),
      zCFA: Number(ethers.utils.formatUnits(zCFA, 18)),
      zZAR: Number(ethers.utils.formatUnits(zZAR, 18)),
    };
    dispatch(updateBalances(ballances));
  };

  const getUserDebt = async () => {
    // get user debt
    try {
      const userDebt = await contract?.getUserDebt(address);

      dispatch(updateUserDebt(String(ethers.utils.formatUnits(userDebt, 18))));
    } catch (error) {
      // navigate("/error")
      console.error(error);
    }
  };
  const getGlobalDebt = async () => {
    // get global debt
    let globalDebt = await contract?.getGlobalDebt();
    dispatch(
      updateGlobalDebt(parseInt(ethers.utils.formatUnits(globalDebt, 18)))
    );
  };

  const getTransactions = async () => {
    let fee = await contract?.swapFee();

    dispatch(updateFee(Number(fee) / 10000));

    let transactions = await window.localStorage.getItem("transactions");
    if (transactions) {
      dispatch(updateTransactions(JSON.parse(transactions)));
    } else {
      dispatch(updateTransactions([]));
    }
  };

  const getRewardBalance = async () => {
    try {
      const colBalance = await contract?.userCollateralBalance(address);
      const cRatio = await contract?.returnLastCollateralRatio(address);
      const userDebt = await contract?.getUserDebt(address);

      let balance: number =
        +colBalance._hex / (+cRatio._hex / 1000) - +userDebt._hex;

      if (+balance / 10 ** 18 < 0 || isNaN(+balance / 10 ** 18))
        return dispatch(updateRewardBalance(0));

      dispatch(updateRewardBalance(+balance / 10 ** 18));
    } catch (error) {
      console.error(error);
    }
  };
}

export default useData;
