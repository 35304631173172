let transactionBody = (chain: any) => {
  return {
    action: "",
    status: "",
    hash: "",
    date: "",
    chain: chain?.id,
    depositBody: {
      mintAmount: 0,
      colAmount: 0,
    },
    swapBody: {
      fromCurrency: "",
      fromAmount: 0,
      toCurrency: "",
      toAmount: 0,
    },
    rewardBody: {
      amount: 0,
    },
    repayBody: {
      repayAmount: 0,
      repayCurrency: "",
      withdrawAmount: 0,
    },
    transferBody: {
      amount: 0,
      recipient: "",
      currency: "",
    },
  };
};

export default transactionBody;
