export const config = {
  vaultAddress: "0x014D6C3E0183BE1cf9e8289f9b03E6D9E82A8340",
  oracleAddress: "0xbE1DE1454aa8F859eAe5adadb0688Ecb6Dd3207d",
  faucetAddress: "0x16A4C191DB629ABC4c2c416b09eB4675A639D3C3",
  zUSD: "0xe23a24f5e6C165d17F49288fB66138bAaf7a55b6",
  zNGN: "0x225B329E94323c148E6B91C788107b3A3265cAF0",
  zZAR: "0x3Dcd5b06E5E155393761b0208338C993279063d7",
  zXAF: "0x55DED2e9AC240561b44FE688D94E90e0CbB13589",
  USDC: "0x50e952747c88897bd969887627A877Eb50339C5A",
  alaCtl: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
  notifyUrl: "http://54.196.54.21:3000/api/v1/error-notify",

  networks: [
    {
      chainId: `0x${Number(43113).toString(16)}`,
      rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
      name: "Avalanche",
      nativeCurrency: {
        name: "AVAX",
        symbol: "AVAX",
        decimals: 18,
      },
      blockExplorerUrls: ["https://explorer.avax.network/"],
    },
  ],
  exchangeRatesAPIKEY: "X2zkqxHTJploZTApTgHFjbJjARZF09wO",
  coinlayerAPIKEY: "49505e855f2ab02b59638b6895755f23",
};
