import Borrow from "../Borrow/Borrow";
import Repay from "../Repay/Repay";
import { useState } from "react";
// import { HiOutlineCurrencyDollar } from "react-icons/hi2";
// import { BiBarChart } from "react-icons/bi";
// import { GoClock } from "react-icons/go";
// import { PiCurrencyDollarLight } from "react-icons/pi";

interface mintProps {
  userColBalance: any;
  userDebt: any;
  value: any;
  // maximumFractionDigits: number;
  // setAction: (arg: string) => void;
  handleClaimReward: () => void;
  rewardBal: any;
  loading: boolean;
}

const MintCard = ({
  // userColBalance,
  // userDebt,
  // value,
  // maximumFractionDigits,
  // setAction,
  handleClaimReward,
  rewardBal,
  loading,
}: mintProps) => {
  const [action, setAction] = useState<string>("deposit");

  return (
    <div className="w-full h-auto p-8 md:p-0 ">
      <div className="w-full overflow-auto mb-11 mx-auto flex justify-center items-center flex-col">
        <div className="w-full space-y-4 ">
          <div className="w-full mint-deposit withdraw">
            <div className="m-depsit-withdraw-switch">
              <button
                className={`${action === "deposit" && "active"}`}
                onClick={() => setAction("deposit")}
              >
                Deposit
              </button>
              <button
                className={`${action === "withdraw" && "active"}`}
                onClick={() => setAction("withdraw")}
              >
                Withdraw
              </button>
            </div>
          </div>
          <div className="">
            {action === "deposit" ? (
              <Borrow
                handleClaimReward={handleClaimReward}
                rewardBal={rewardBal}
                claimLoading={loading}
              />
            ) : (
              <Repay
                handleClaimReward={handleClaimReward}
                rewardBal={rewardBal}
                claimLoading={loading}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MintCard;
