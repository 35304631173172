import ZUSD from "../../assets/ZUSD.png";
import ZNGN from "../../assets/ZNGN.png";
import ZXAF from "../../assets/ZXAF.png";
import ZZAR from "../../assets/ZZAR.png";
import { CgArrowsExchangeAltV } from "react-icons/cg";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { PiSpinnerBold } from "react-icons/pi";
import { removeLastNonZeroDigit } from "../../helper";

interface swapProps {
  fromZAsset: string;
  toZAsset: string;
  setFromAmount: (arg: any) => void;
  fromAmount: any;
  toAmount: any;
  zUSDBal: any;
  zXAFBal: any;
  zZARBal: any;
  zNGNBal: any;
  rate: any;
  fee: any;
  showQuote: any;
  swapOutput: any;
  showFromZAssets: boolean;
  setShowFromZAssets: (arg: boolean) => void;
  showToZAssets: boolean;
  setShowToZAssets: (arg: any) => void;
  handleFields: (arg: any, arg2: any) => void;
  selectFromZAsset: (arg: any) => void;
  selectToZAsset: (arg: any) => void;
  setToAmount: (arg: any) => void;
  handleApprove: () => void;
  handleSwap: () => void;
  _approve: boolean;
  loading: boolean;
  buttonPressed?: boolean;
}

const SwapCard = ({
  fromZAsset,
  toZAsset,
  rate,
  fee,
  swapOutput,
  fromAmount,
  toAmount,
  zUSDBal,
  zZARBal,
  zXAFBal,
  zNGNBal,
  showFromZAssets,
  showToZAssets,
  showQuote,
  setShowToZAssets,
  selectFromZAsset,
  setShowFromZAssets,
  selectToZAsset,
  handleFields,
  setToAmount,
  handleApprove,
  handleSwap,
  setFromAmount,
  _approve,
  loading,
  buttonPressed,
}: swapProps) => {
  return (
    <div className="swap-ztokens w-full max-w-md lg:max-w-lg xl:max-w-2xl h-min bg-[#FFFAF3] border-thin border-gray-400 rounded-md py-3 px-3 shadow-md">
      <p className="text-xs mx-auto w-full mb-3 text-center mt-2">
        Select the asset you would like to deposit
      </p>

      <form action="">
        <label htmlFor="walletAddress" className="w-full">
          <div className="inline-flex justify-between w-full text-end mb-1 text-[x-small]">
            <p>REPAY TOKEN</p>
            <div>
              {fromZAsset === "zUSD" && (
                <p
                  onClick={() => setFromAmount(removeLastNonZeroDigit(zUSDBal))}
                >
                  <span className="me-1">{fromZAsset} Balance:</span>
                  <span>
                    {zUSDBal?.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </p>
              )}
              {fromZAsset === "zNGN" && (
                <p
                  onClick={() => setFromAmount(removeLastNonZeroDigit(zNGNBal))}
                >
                  <span className="me-1">{fromZAsset} Balance:</span>
                  <span>
                    {zNGNBal?.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </p>
              )}
              {fromZAsset === "zXAF" && (
                <p
                  onClick={() => setFromAmount(removeLastNonZeroDigit(zXAFBal))}
                >
                  <span className="me-1">{fromZAsset} Balance:</span>
                  <span>
                    {zXAFBal?.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </p>
              )}
              {fromZAsset === "zZAR" && (
                <p
                  onClick={() => setFromAmount(removeLastNonZeroDigit(zZARBal))}
                >
                  Balance:
                  <span>
                    {zZARBal?.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </p>
              )}
            </div>
          </div>

          <div className="inline-flex bg-white border-thin border-gray-400 w-full p-2 gap-3 rounded-md">
            <div
              className="relative inline-flex items-center border-r-thin w-max gap-3 pe-1 border-gray-400"
              onClick={() => setShowFromZAssets(!showFromZAssets)}
            >
              <span className="inline-flex space-x-1 me-1 text-xs">
                {fromZAsset && (
                  <>
                    {fromZAsset === "zUSD" && (
                      <img src={ZUSD} alt="ZUSD" className="h-5" />
                    )}
                    {fromZAsset === "zNGN" && (
                      <img src={ZNGN} alt="ZNGN" className="h-5" />
                    )}
                    {fromZAsset === "zXAF" && (
                      <img src={ZXAF} alt="ZXAF" className="h-5" />
                    )}
                    {fromZAsset === "zZAR" && (
                      <img src={ZZAR} alt="ZZAR" className="h-5" />
                    )}
                  </>
                )}
                <p className="text-xs">
                  {fromZAsset ? fromZAsset : "Choose Asset"}
                </p>
              </span>
              {showFromZAssets ? (
                <AiOutlineUp className="text-[#5A5A65] h-3" />
              ) : (
                <AiOutlineDown className="text-[#5A5A65] h-3" />
              )}
              {showFromZAssets && (
                <div className="flex flex-col py-2 z-10 text-font-grey cursor-pointer absolute rounded bg-white min-w-max text-sm space-y-1.5 border-thin border-gray-400 -right-1 top-[110%]">
                  <div
                    className="inline-flex gap-1 hover:bg-[#EAD0A9] px-2 py-0.5 duration-300 ease-out"
                    onClick={() => selectFromZAsset("zUSD")}
                  >
                    <img src={ZUSD} alt="zusd" className="h-5" />
                    <p className="ml-2">zUSD</p>
                  </div>
                  <div
                    className="inline-flex gap-1 hover:bg-[#EAD0A9] px-2 py-0.5 duration-300 ease-out"
                    onClick={() => selectFromZAsset("zNGN")}
                  >
                    <img src={ZNGN} alt="" className="h-5" />
                    <p className="ml-2">zNGN</p>
                  </div>
                  <div
                    className="inline-flex gap-1 hover:bg-[#EAD0A9] px-2 py-0.5 duration-300 ease-out"
                    onClick={() => selectFromZAsset("zXAF")}
                  >
                    <img src={ZXAF} alt="" className="h-5" />
                    <p className="ml-2">zXAF</p>
                  </div>
                  <div
                    className="inline-flex gap-1 hover:bg-[#EAD0A9] px-2 py-0.5 duration-300 ease-out"
                    onClick={() => selectFromZAsset("zZAR")}
                  >
                    <img src={ZZAR} alt="" className="h-5" />
                    <p className="ml-2">zZAR</p>
                  </div>
                </div>
              )}
            </div>
            <input
              name="repayToken"
              type="number"
              placeholder="0"
              value={fromAmount}
              onChange={(e) => handleFields(e.target.value, "From")}
              disabled={fromZAsset && toZAsset ? false : true}
              className="w-full px-2 text-right focus:outline-none"
            />
          </div>
        </label>

        <div className="mt-6">
          <div className="relative w-full my-2 border-thin  border-gray-300">
            <div className="absolute inset-x-[49%] inset-y-[49%] my-auto rounded-full bg-[#FEE7C0] p-1 w-5 h-5">
              <CgArrowsExchangeAltV className="h-full w-auto text-[#D6A45B]" />
            </div>
          </div>
        </div>

        <label htmlFor="w" className="w-full">
          <div className="inline-flex justify-between w-full text-end mb-1 text-[x-small]">
            <p>WITHDRAWL COLLATERAL</p>
            <div>
              {toZAsset === "zUSD" && (
                <p onClick={() => setToAmount(removeLastNonZeroDigit(zUSDBal))}>
                  <span className="me-1">{toZAsset} Balance:</span>
                  <span>
                    {zUSDBal?.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </p>
              )}
              {toZAsset === "zNGN" && (
                <p onClick={() => setToAmount(removeLastNonZeroDigit(zNGNBal))}>
                  <span className="me-1">{toZAsset} Balance:</span>
                  <span>
                    {zNGNBal?.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </p>
              )}
              {toZAsset === "zXAF" && (
                <p onClick={() => setToAmount(removeLastNonZeroDigit(zXAFBal))}>
                  <span className="me-1">{toZAsset} Balance:</span>
                  <span>
                    {zXAFBal?.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </p>
              )}
              {toZAsset === "zZAR" && (
                <p onClick={() => setToAmount(removeLastNonZeroDigit(zZARBal))}>
                  <span className="me-1">{toZAsset} Balance:</span>
                  <span>
                    {zZARBal?.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </p>
              )}
            </div>
          </div>

          <div className="inline-flex bg-white border-thin border-gray-400 w-full p-2 gap-3 rounded-md">
            <div
              className="relative inline-flex items-center border-r-thin w-max gap-3 pe-1 border-gray-400"
              onClick={() => setShowToZAssets(!showToZAssets)}
            >
              <span className="inline-flex space-x-1 me-1 text-xs">
                {toZAsset && (
                  <>
                    {toZAsset === "zUSD" && (
                      <img src={ZUSD} alt="ZUSD" className="h-5" />
                    )}
                    {toZAsset === "zNGN" && (
                      <img src={ZNGN} alt="ZNGN" className="h-5" />
                    )}
                    {toZAsset === "zXAF" && (
                      <img src={ZXAF} alt="ZXAF" className="h-5" />
                    )}
                    {toZAsset === "zZAR" && (
                      <img src={ZZAR} alt="ZZAR" className="h-5" />
                    )}
                  </>
                )}
                <p className="text-xs">
                  {toZAsset ? toZAsset : "Choose Asset"}
                </p>
              </span>
              {showToZAssets ? (
                <AiOutlineUp className="text-[#5A5A65] h-3" />
              ) : (
                <AiOutlineDown className="text-[#5A5A65] h-3" />
              )}
              {showToZAssets && (
                <div className="flex flex-col py-2 text-font-grey cursor-pointer absolute rounded bg-white min-w-max text-sm space-y-1.5 border-thin border-gray-400 -right-1 top-[110%]">
                  <div
                    className="inline-flex gap-1 hover:bg-[#EAD0A9] px-2 py-0.5 duration-300 ease-out"
                    onClick={() => selectToZAsset("zUSD")}
                  >
                    <img src={ZUSD} alt="zusd" className="h-5" />
                    <p className="ml-2">zUSD</p>
                  </div>
                  <div
                    className="inline-flex gap-1 hover:bg-[#EAD0A9] px-2 py-0.5 duration-300 ease-out"
                    onClick={() => selectToZAsset("zNGN")}
                  >
                    <img src={ZNGN} alt="" className="h-5" />
                    <p className="ml-2">zNGN</p>
                  </div>
                  <div
                    className="inline-flex gap-1 hover:bg-[#EAD0A9] px-2 py-0.5 duration-300 ease-out"
                    onClick={() => selectToZAsset("zXAF")}
                  >
                    <img src={ZXAF} alt="" className="h-5" />
                    <p className="ml-2">zXAF</p>
                  </div>
                  <div
                    className="inline-flex gap-1 hover:bg-[#EAD0A9] px-2 py-0.5 duration-300 ease-out"
                    onClick={() => selectToZAsset("zZAR")}
                  >
                    <img src={ZZAR} alt="" className="h-5" />
                    <p className="ml-2">zZAR</p>
                  </div>
                </div>
              )}
            </div>
            <input
              type="number"
              placeholder="0"
              value={toAmount}
              onChange={(e) => handleFields(e.target.value, "To")}
              disabled={toZAsset && toZAsset ? false : true}
              className="w-full px-2 text-right focus:outline-none"
            />
          </div>
        </label>
      </form>

      {showQuote && (
        <div className="w-full text-xs mt-2">
          <div className="flex justify-between">
            <p className="text-[#794118]"> Exchange Rate:</p>
            <p className="text-[#794118]">
              1 {fromZAsset} = {rate?.toFixed(2)} {toZAsset}
            </p>
          </div>
          <div className="flex justify-between">
            <p className="text-[#794118]"> Fees:</p>
            <p className="text-[#794118]">{fee}%</p>
          </div>
          <div className="flex justify-between">
            <p className="text-[#794118]">Expected Output :</p>
            <p className="text-[#794118]">
              {swapOutput - (swapOutput * fee) / 100} {toZAsset}
            </p>
          </div>
          <div className="flex justify-between">
            <p className="text-[#794118]"> Trading fee:</p>
            <p className="text-[#794118]">
              {" "}
              {(swapOutput * fee) / 100} {toZAsset}
            </p>
          </div>
        </div>
      )}

      {buttonPressed && (
        <p className="text-xs md:hidden py-2 text-center">
          Please check your wallet to confirm
        </p>
      )}

      <button
        onClick={() => (!_approve ? handleApprove() : handleSwap())}
        className={`w-full text-sm mt-4 ${
          !_approve ? "bg-[#FB7F37]" : "bg-black"
        } text-white rounded p-2  flex justify-center items-center `}
      >
        {!_approve ? (
          <>
            {loading ? (
              <PiSpinnerBold className="h-4 w-4 animate-spin" />
            ) : (
              "Approve"
            )}
          </>
        ) : (
          <>
            {" "}
            {loading ? (
              <PiSpinnerBold className="h-4 w-4 animate-spin" />
            ) : (
              "Swap"
            )}
          </>
        )}
      </button>
    </div>
  );
};

export default SwapCard;
