/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, Fragment, useState } from "react";
import MobileLayout from "../../../layouts/Mobile/MobileLayout";
import TransferCard from "../../../components/Transfer/TransferCard";
import useToken from "../../../hooks/useToken";
import useSwap from "../../../hooks/useSwap";
import useOracle from "../../../hooks/useOracle";
import axios from "axios";
import { config } from "../../../config";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import useData from "../../../hooks/useData";
import useTransfer from "../../../hooks/useTransfer";
import { Link } from "react-router-dom";

axios.defaults.headers.common["apikey"] = config.exchangeRatesAPIKEY;

const Transfer = () => {
  const [fromAmount, setFromAmount] = useState<any>(0);
  const [toAmount, setToAmount] = useState<any>(0);
  const _ = useData();
  const [showFromZAssets, setShowFromZAssets] = useState<boolean>(false);
  const [showToZAssets, setShowToZAssets] = useState<boolean>(false);
  const [fromZAsset, setFromZAsset] = useState<string>("zUSD");
  const [toZAsset, setToZAsset] = useState<string>("zNGN");
  const [address, setAddress] = useState<string>("");
  const [iSwap, setIswap] = useState<boolean>(false);
  const [_approve, setApprove] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonPressed, setButtonPressed] = useState<boolean>(false);
  const [rate, setRate] = useState<number>(0);
  const [swapOutput, setSwapOutput] = useState<number>(0);
  const { approve, allowance } = useToken(fromZAsset, true);
  const { swap } = useSwap();
  const { transfer, transferApprove } = useTransfer(toZAsset, toAmount, address);
  const {
    getNGNUSD,
    getXAFUSD,
    getZARUSD,
    getCOLUSD,
    getNGNXAF,
    getZARXAF,
    getNGNZAR,
  } = useOracle();

  const { zUSDBal, zNGNBal, zXAFBal, zZARBal, fee } = useSelector(
    (state: any) => state.baki
  );

  const getRates = async () => {
    try {
      let NGNUSDRate = await getNGNUSD();
      let XAFUSDRate = await getXAFUSD();
      let ZARUSDRate = await getZARUSD();
      let COLUSDRate = await getCOLUSD();
      let NGNXAFRate = await getNGNXAF();
      let ZARXAFRate = await getZARXAF();
      let NGNZARRate = await getNGNZAR();

      return {
        NGN: NGNUSDRate,
        XAF: XAFUSDRate,
        ZAR: ZARUSDRate,
        USD: COLUSDRate,
        NGNXAF: 1 / NGNXAFRate,
        ZARXAF: 1 / ZARXAFRate,
        NGNZAR: 1 / NGNZARRate,
        XAFNGN: NGNXAFRate,
        XAFZAR: ZARXAFRate,
        ZARNGN: NGNZARRate,
      };
    } catch (error) {
      console.error(error);
    }
  };


  const handleApprove = async () => {
    if(!address)return toast.warn("Please provide address");
    if (loading) return;
    if (!_approve) {
      setLoading(true);
      let result = await approve(fromAmount);
      if (result) {
        setApprove(true);
      }
      setLoading(false);
    }
  };

  const handleSwap = async () => {
    if(!address) return toast.warn("Please provide address");
    if (loading) return;
    if (fromZAsset === toZAsset) return;
    if (fromAmount && toAmount && _approve) {
      setLoading(true);
      const result = await swap(fromAmount, fromZAsset, toZAsset, swapOutput);

      if (result) {
        setIswap(false);
        setLoading(false);
        toast.success("Swap Successful !!");
      } else {
        setLoading(false);
      }
    }
  };

  const handleTransfer = async () => {
    if(!address) return toast.warn("Please provide address");
    if (loading) return;
    if (toAmount && _approve) {
      setLoading(true);
      const result = await transfer();

      if (result) {
        setLoading(false);
        toast.success("Transaction Successful !!");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (toZAsset !== fromZAsset) {
      setIswap(true);
    } else {
      setIswap(false);
    }
  }, [toZAsset, fromZAsset]);

  const handleFields = (_value: string, _field: string) => {
    if (_field === "To") {
      setToAmount(Number(_value));
      setFromAmount(Number(_value) / rate);
    } else if (_field === "From") {
      setFromAmount(Number(_value));
      setToAmount(Number(_value) * rate);
    }
  };

  useEffect(() => {
    if (fromAmount > allowance) {
      setApprove(true);
    } else {
      setApprove(false);
    }
  }, [fromAmount, toAmount, allowance]);

  useEffect(() => {
    if (!fromAmount && !toAmount && !rate) {
      getRates().then((result: any) => {
        setRate(result?.[`${toZAsset.substring(1)}`]);
      });
    }
  }, [getRates]);

  useEffect(() => {
    if (fromAmount) {
      setLoading(true);
      setLoading(true);
      getRates()
        .then((result: any) => {
          if (fromZAsset.substring(1) === "USD") {
            setRate(result?.[`${toZAsset.substring(1)}`]);
            const output = result?.[`${toZAsset.substring(1)}`] * fromAmount;
            setSwapOutput(output);
            setToAmount(output);
          }

          if (toZAsset.substring(1) === "USD") {
            setRate(1 / result?.[`${fromZAsset.substring(1)}`]);
            const output =
              (1 / result?.[`${fromZAsset.substring(1)}`]) * fromAmount;
            setSwapOutput(output);
            setToAmount(output);
          }

          if (
            fromZAsset.substring(1) !== "USD" &&
            toZAsset.substring(1) !== "USD"
          ) {
            setRate(
              result?.[`${fromZAsset.substring(1)}${toZAsset.substring(1)}`]
            );
            const output =
              result?.[`${fromZAsset.substring(1)}${toZAsset.substring(1)}`] *
              fromAmount;
            setSwapOutput(output);
            setToAmount(output);
          }
          if (fromZAsset.substring(1) === toZAsset.substring(1)) {
            setRate(1);
            const output = 1 * fromAmount;
            setSwapOutput(output);
            setToAmount(output);
          }

          setLoading(false);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setLoading(false);
        });
    } else {
      getRates().then((res: any) => {
        if (fromZAsset.substring(1) === toZAsset.substring(1)) {
          return setRate(1);
        }

        if (toZAsset.substring(1) === "USD") {
          return setRate(1 / res?.[`${fromZAsset.substring(1)}`]);
        }

        if (fromZAsset.substring(1) === "USD") {
          return setRate(res?.[`${toZAsset.substring(1)}`]);
        }

        if (
          toZAsset.substring(1) !== "USD" &&
          fromZAsset.substring(1) !== "USD"
        ) {
          return setRate(
            res?.[`${fromZAsset.substring(1)}${toZAsset.substring(1)}`]
          );
        }
      });
    }
  }, [fromAmount, rate, toZAsset, fromZAsset]);

  const selectFromZAsset = (_asset: string) => {
    setFromZAsset(_asset);
    setShowFromZAssets(false);
  };

  const selectToZAsset = (_asset: string) => {
    setToZAsset(_asset);
    setShowToZAssets(false);
  };
  return (
    <MobileLayout>
      <div className="grid p-4 place-content-center">
        <p className="md:hidden text-xs mx-auto w-full mb-3 mt-2">
          Select the asset you would like to send and enter an amount
        </p>
        <Link to="/transactions" className="px-1 mb-3">
          <p className="m-transactions-text">View Transactions</p>
        </Link>
        <div className="flex justify-center items-center mb-4 w-full">
          <div className="flex justify-center items-center flex-col  w-[30%]">
            <div className="border rounded-full p-2 h-[23px] flex justify-center items-center text-[12px] text-[#241F17]">
              1
            </div>
            <p
              className={`text-[12px] ${
                fromZAsset !== toZAsset && iSwap
                  ? `text-[#241F17]`
                  : `text-[#ccc]`
              }`}
            >
              Swap
            </p>
          </div>
          <div
            className={`h-[1px] w-[30%] ${
              fromZAsset === toZAsset || !iSwap ? `bg-[#241F17]` : `bg-[#ccc]`
            }`}
          ></div>
          <div className="flex justify-center items-center flex-col w-[30%]">
            <div className="border rounded-full p-2 h-[23px] flex justify-center items-center text-[12px] text-[#241F17]">
              2
            </div>
            <p
              className={`text-[12px]  ${
                fromZAsset === toZAsset || !iSwap
                  ? `text-[#241F17]`
                  : `text-[#ccc]`
              }`}
            >
              Transfer
            </p>
          </div>
        </div>
        <TransferCard
          fromZAsset={fromZAsset}
          toZAsset={toZAsset}
          fromAmount={fromAmount}
          toAmount={toAmount}
          selectToZAsset={selectToZAsset}
          selectFromZAsset={selectFromZAsset}
          setFromAmount={setFromAmount}
          setToAmount={setToAmount}
          zUSDBal={zUSDBal}
          zNGNBal={zNGNBal}
          zXAFBal={zXAFBal}
          zZARBal={zZARBal}
          showFromZAssets={showFromZAssets}
          setShowFromZAssets={setShowFromZAssets}
          showToZAssets={showToZAssets}
          setShowToZAssets={setShowToZAssets}
          fee={fee}
          rate={rate}
          _approve={_approve}
          loading={loading}
          handleApprove={handleApprove}
          handleSwap={handleSwap}
          handleFields={handleFields}
          setAddress={setAddress}
          handleTransfer={handleTransfer}
          iSwap={iSwap}
          transferApprove={transferApprove}
        />
      </div>
    </MobileLayout>
  );
};

export default Transfer;
