/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import "./Liquidation.css";
import MobileLayout from "../../../layouts/Mobile/MobileLayout";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import useLiquidations from "../../../hooks/useLiquidations";
import EmptyTable from "../../../assets/emptytable.png";
import { LiquidateBTN } from "../../App/Liquidation/Liquidation";
import "./Liquidation.css";

const Liquidation = () => {
  const { _liquidate } = useLiquidations();
  const { liquidations, liquidLoading } = useSelector(
    (state: any) => state.baki
  );
  const numbers = Array.from({ length: 10 }, (_, index) => index);

  const handleLiquidate = async (_address: string) => {
    const result = await _liquidate(_address);
    if (result) {
      toast.success("Liquidation Successful !!");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      return;
    } else {
      toast.error("Liquidation Failed !!");
      return;
    }
  };
  return (
    <MobileLayout>
      <div className="px-6 flex flex-col overflow-auto h-full w-full py-3 m-scoll-body">
        {!liquidLoading &&
          liquidations?.map((liquidation: any, index: number) => (
            <div
              className="w-full bg-[#FFF9F1] mb-3 py-2 px-3 flex justify-between rounded-md text-xs font-medium border-thin border-gray-300 focus:border-black"
              key={index}
            >
              <div className=" space-y-1.5">
                <p className="font-normal bg-orange-100 border-thin border-orange-300 w-fit py-1 px-2 rounded-xl">
                  OWNER :{" "}
                  <span className="text-xs">
                    {" "}
                    {liquidation.address?.slice(0, 5)}...{" "}
                    {/* {liquidation.address?.slice(35, 50)} */}
                    {liquidation.address?.slice(35, 40)}
                  </span>{" "}
                </p>

                <p className="font-medium text-base text-amber-800">
                  <span className="me-1 font-normal">Potential Reward:</span>
                  {`${liquidation.reward?.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}  USDC`}
                </p>
                <p className="font-normal">
                  Required zUSD: {liquidation.requiredZUSD}
                </p>
              </div>

              <div className="flex h-full font-normal">
                <LiquidateBTN
                  liquidation={liquidation}
                  handleLiquidate={handleLiquidate}
                />
              </div>
            </div>
          ))}

        {!liquidations?.length && !liquidLoading && (
          <div className="w-full h-full grid place-content-center space-y-4">
            <img
              src={EmptyTable}
              alt=""
              srcSet=""
              className="h-24 w-24 place-self-center mx-auto"
            />
            <p className="text-xs">No Liquidations were found!</p>
          </div>
        )}

        {liquidLoading &&
          numbers.map(() => (
            <div className="w-full rounded-lg bg-[#FFF9F1] my-1.5 h-20 space-y-2 py-2 px-3 border-thin border-[#FEE7C0]">
              <div className="inline-flex h-full w-full gap-2 my-auto">
                <div className="basis-4/5 flex flex-col gap-2 h-full">
                  <div className="rounded-md w-3/5 bg-[#FEE7C0] h-5 animate-pulse "></div>
                  <div className="rounded-md w-4/5 bg-[#FEE7C0] h-5 animate-pulse "></div>
                  <div className="rounded-md w-2/5 bg-[#FEE7C0] h-4 animate-pulse "></div>
                </div>
                <div className="basis-1/5 h-full flex place-items-center">
                  <div className="w-4/5 h-5 rounded-md ms-auto bg-[#FEE7C0] animate-pulse "></div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </MobileLayout>
  );
};

export default Liquidation;
