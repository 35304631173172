import React from "react";
import MainLayout from "../../../layouts/Desktop/MainLayout";
import "./Transactions.css";
import Transactiions from "../../../components/Home/Transactions/Transactions";
function Transactions() {
  return (
    <>
      <MainLayout>
       <Transactiions/>
      </MainLayout>
    </>
  );
}

export default Transactions;
