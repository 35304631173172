/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState, useContext } from "react";
import MainLayout from "../../../layouts/Desktop/MainLayout";
import { useSelector } from "react-redux";
import useDeposit from "../../../hooks/useDeposit";
import useData from "../../../hooks/useData";
import TourContext from "../../../TourGuideContext";

import { toast } from "react-toastify";

import { HiOutlineCurrencyDollar } from "react-icons/hi2";
import { BiBarChart } from "react-icons/bi";
import { GoClock } from "react-icons/go";
import { PiCurrencyDollarLight } from "react-icons/pi";
import MintCard from "../../../components/Mint/MintCard";

declare const window: any;

function Mint() {
  const _ = useData();
  const [action, setAction] = useState<string>("deposit");
  const [value, setValue] = useState<number>();
  const { getUSDValue, deposit } = useDeposit();
  const { userColBalance, userDebt, network, rewardBal } = useSelector(
    (state: any) => state.baki
  );
  
  const [loading, setLoading] = useState<boolean>(false);
  const { setShowGuide, tourRunning } = useContext(TourContext);

  const handleClaimReward = async () => {
    if (rewardBal / 10 ** -6 <= 0) return;
    setLoading(true);
    let result = await deposit(0, rewardBal);
    if (result) {
      toast.success("Transaction Successful !!");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUSDValue(userColBalance).then((_value) => {
      setValue(_value);
    });
  }, [value, getUSDValue, userColBalance]);

  useEffect(() => {
    const delayTour = setTimeout(() => {
      if (localStorage.getItem("tour-completed") === null && !tourRunning) {
        setShowGuide(true);
      }
    }, 1500);
    return () => clearTimeout(delayTour);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout>
      <br className="hidden md:block" />
      <div className="p-4 md:p-0 w-full h-auto">
        <div className="w-full max-w-md lg:max-w-2xl xl:max-w-4xl overflow-auto mb-11 mx-auto flex justify-center items-center flex-col">
          <div className="w-full space-y-4">
            <div className="grid grid-flow-col grid-cols-4 gap-2">
              <div className="detail_tw">
                <div className="inline-flex justify-between w-full">
                  <p className=" health text-[xx-small] my-auto">
                    POSITION HEALTH
                  </p>
                  <BiBarChart className="h-5 my-auto" color={"#A49C94"} />
                </div>
                <p
                  className="text-xs p-0 m-0"
                  style={{
                    color:
                      userColBalance > 0
                        ? userColBalance / userDebt >= 1.5
                          ? "green"
                          : "red"
                        : "grey",
                    marginTop: 5,
                  }}
                >
                  {userDebt === 0
                    ? "0"
                    : ((userColBalance / userDebt) * 100).toFixed(2)}{" "}
                  %
                </p>
              </div>
              <div className="detail_tw">
                <div className="inline-flex justify-between w-full">
                  <p className="text-[xx-small] my-auto">COLLATERAL</p>
                  <HiOutlineCurrencyDollar
                    className="h-5 my-auto"
                    color={"#A49C94"}
                  />
                </div>

                <p className="text-xs p-0 m-0">
                  {Number(Number(userColBalance).toFixed(2))?.toLocaleString(
                    undefined,
                    {
                      maximumFractionDigits: 2,
                    }
                  )}{" "}
                  USDC
                </p>
              </div>
              <div className="detail_tw">
                <div className="inline-flex justify-between w-full">
                  <p className="text-[xx-small] my-auto">VALUE</p>
                  <PiCurrencyDollarLight
                    className="h-5 my-auto"
                    color={"#A49C94"}
                  />
                </div>

                <p className="text-xs p-0 m-0">
                  {Number(value?.toFixed(2))?.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}
                </p>
              </div>
              <div className="detail_tw">
                <div className="inline-flex justify-between w-full">
                  <p className="text-[xx-small] my-auto">DEBT</p>
                  <GoClock className="h-5 my-auto" color={"#A49C94"} />
                </div>

                <p className="text-xs p-0 m-0">
                  {Number(Number(userDebt).toFixed(2))?.toLocaleString(
                    undefined,
                    {
                      maximumFractionDigits: 2,
                    }
                  )}
                </p>
              </div>
            </div>
            {/* MintCard */}
            <MintCard
              userColBalance={userColBalance}
              userDebt={userDebt}
              value={value}
              handleClaimReward={handleClaimReward}
              rewardBal={rewardBal}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default Mint;
