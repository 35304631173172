/* eslint-disable react-hooks/exhaustive-deps */
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useContext } from "react";
import "./TourGuide.css";
import card from "../../assets/tour-guide/card.png";
import rocket from "../../assets/tour-guide/rocket.png";
import { useNavigate } from "react-router-dom";
import TourContext from "../../TourGuideContext";

interface TourGuideProp {
  isOpen: boolean;
  setIsOpen: any;
  driver: any;
}

// Add To Home Screen Modal
export default function GuideIntro({
  isOpen,
  setIsOpen,
  driver,
}: TourGuideProp) {
  const navigate = useNavigate();

  const { tourLoading, setTourLoading } = useContext(TourContext);

  async function closeModal() {
    await setIsOpen(false);
  }

  async function handleStart() {
    await navigate("/mint");
    setTourLoading(true);
    setTimeout(async () => {
      await setIsOpen(false);
      await driver.drive();
    }, 300);
  }

  useEffect(() => {
    setTourLoading(false);
  }, []);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/75" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative lg:static w-full max-w-xl transform lg:inline-flex overflow-hidden rounded-md bg-white text-left align-middle shadow-xl transition-all waves-bg">
                  <div className="basis-1/2 my-auto p-6">
                    <Dialog.Title
                      as="h3"
                      className="lg:text-2xl font-medium leading-6 text-gray-900"
                    >
                      Welcome to Baki!
                    </Dialog.Title>
                    <div className="mt-3 flex gap-2 flex-col">
                      {/* <img src={Rocket} alt="rocket" /> */}
                      <p className="text-xs lg:text-sm">
                        Thank you for joining Baki. Ready to take off on your
                        DeFi journey?!
                      </p>
                      <p className="text-xs lg:text-sm">
                        Click the button below to take our quick tour and get
                        started on using Baki.
                      </p>
                    </div>

                    <div className="mt-4 inline-flex gap-2">
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-lg border-0 bg-black text-white px-4 py-2 text-xs font-sm hover:bg-black/20 focus:outline-none focus-visible:ring-2 focus-visible:ring-[#f7d8b8] focus-visible:ring-offset-1"
                        onClick={handleStart}
                      >
                        Take a quick tour
                      </button>
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border-0 px-1 py-2 text-xs focus:outline-none hover:font-semibold"
                        onClick={() => {
                          if (!localStorage.getItem("tour-completed")) {
                            localStorage.setItem("tour-completed", "true");
                          }
                          setIsOpen(false);
                        }}
                      >
                        Skip this
                      </button>
                    </div>
                  </div>
                  <div className="relative hidden lg:block basis-1/2 min-h-full lg:p-6">
                    <div className="w-full h-1/2 p-2">
                      <img src={card} className="object-fit" alt="card" />
                    </div>
                    <img
                      src={rocket}
                      className={`absolute top-[25%] right-[25%] lg:h-4/5 ${
                        tourLoading && "animate-float"
                      }`}
                      alt="to the moon!"
                    />
                  </div>
                  <img
                    src={rocket}
                    className={`absolute lg:hidden top-[46%] right-[3%] h-1/2 ${
                      tourLoading && "animate-float"
                    }`}
                    alt="to the moon!"
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
