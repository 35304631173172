/* eslint-disable react-hooks/exhaustive-deps */
import { useNetwork } from "wagmi";
import MobileLayout from "../../../layouts/Mobile/MobileLayout";
import "./Transaction.css";
import { MdOpenInNew } from "react-icons/md";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Transactions = () => {
  const { transactions, address } = useSelector((state: any) => state.baki);
  const { chain }: any = useNetwork();
  const [txns, setTxns] = useState([]);

  useEffect(() => {
    let _txns = transactions[address]?.filter(
      (tx: any) => tx.chain === chain?.id
    );
    setTxns(_txns);
  }, [transactions]);

  return (
    <MobileLayout>
      <div className="flex flex-col px-6 overflow-auto h-full w-full py-3 m-scoll-body">
        {!txns?.length && (
          <div className="w-full h-full flex flex-col justify-center items-center gap-2">
            <img
              src="/images/empty.png"
              alt=""
              className="h-24 w-24 place-self-center mx-auto"
            />
            <div className="transactions-no">
              <p className="text-xs">You have no recent transactions!</p>
            </div>
          </div>
        )}
        {txns?.map((transaction: any, index: number) => (
          <div className="m-liquidation mb-2" key={index}>
            <div>
              {transaction.action === "Deposit" && (
                <p className="text-[10px] text-[#794118]">{`Deposited ${transaction.depositBody.colAmount?.toLocaleString(
                  undefined,
                  {
                    maximumFractionDigits: 2,
                  }
                )} USDC and Minted ${transaction.depositBody.mintAmount?.toLocaleString(
                  undefined,
                  {
                    maximumFractionDigits: 2,
                  }
                )} zUSD`}</p>
              )}
              {transaction.action === "Swap" && (
                <p className="text-[10px] text-[#794118]">{`Swap ${transaction.swapBody.fromAmount?.toLocaleString(
                  undefined,
                  {
                    maximumFractionDigits: 2,
                  }
                )}  ${
                  transaction.swapBody.fromCurrency
                } for ${transaction.swapBody.toAmount?.toLocaleString(
                  undefined,
                  {
                    maximumFractionDigits: 2,
                  }
                )} ${transaction.swapBody.toCurrency}`}</p>
              )}
              {transaction.action === "Reward" && (
                <p className="text-[10px] text-[#794118]">{`${transaction.rewardBody.amount?.toLocaleString(
                  undefined,
                  {
                    maximumFractionDigits: 2,
                  }
                )} zUSD  Claimed in Rewards`}</p>
              )}
              {transaction.action === "Withdraw" && (
                <p className="text-[10px] text-[#794118]">{`Repaid ${transaction.repayBody.repayAmount?.toLocaleString(
                  undefined,
                  {
                    maximumFractionDigits: 2,
                  }
                )} ${
                  transaction.repayBody.repayCurrency
                } and Withdrawn ${transaction.repayBody.withdrawAmount?.toLocaleString(
                  undefined,
                  {
                    maximumFractionDigits: 2,
                  }
                )} USDC`}</p>
              )}
              <p
                className="text-[10px] text-[#794118] mt-2"
                style={{
                  color: transaction.status === "Successful" ? "green" : "red",
                }}
              >
                {transaction.status}
              </p>
              <p className="text-[10px] text-[#794118] mt-2">
                {transaction.date}
              </p>
            </div>
            {transaction.hash && (
              <a
                className="text-[10px] text-[#794118] text-underline flex items-center"
                href={
                  transaction.hash
                    ? `https://${
                        chain?.id === 43114 ? "" : "testnet."
                      }avascan.info/blockchain/c/tx/${transaction.hash}`
                    : "/mint"
                }
                target={transaction.hash && "_blank"}
              >
                <span className="mr-1">View</span>
                <MdOpenInNew size={12} />
              </a>
            )}
          </div>
        ))}
      </div>
    </MobileLayout>
  );
};

export default Transactions;
