/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState, useRef, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./MobileLayout.css";
import { useAccount, useChainId } from "wagmi";
import useConnector from "../../hooks/useConnector";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { BsArrowRepeat } from "react-icons/bs";
import { IoArrowRedoOutline } from "react-icons/io5";
import useTourGuide from "../../hooks/useTourGuide";

import GuideIntro from "../../components/TourGuide/GuideIntro";
import GuideOutro from "../../components/TourGuide/GuideOutro";
import TourContext from "../../TourGuideContext";
import FloatingButton from "../../components/Mobile/FloatingButton/FloatingBotton";
import { LuArrowUpRightFromCircle } from "react-icons/lu";
import { PiCurrencyDollar, PiLayout } from "react-icons/pi";
import { registerUser } from "../../airtable";

interface MobileLayoutProps {
  children: React.ReactNode;
}
const MobileLayout: FC<MobileLayoutProps> = ({ children }) => {
  const { switchNetwork, addTokens } = useConnector();
  // const chain = 43113;
  const chain = useChainId();
  const { open } = useWeb3Modal();
  const [show, setShow] = useState<boolean>();
  const location: any = useLocation();
  const { address, isConnected } = useAccount();
  // const address = "theektehe";
  let footerRef = useRef(null);
  const { showGuide, setShowGuide, showGuideCompleted, setShowGuideCompleted } =
    useContext(TourContext);
  const { TourGuide } = useTourGuide();

  // useEffect(() => {
  //   const footer = footerRef.current.clientHeight;
  //   const footerHeight = footer?.offsetHeight;
  //   console.log(footerHeight);
  // }, []);
  useEffect(() => {
    if (isConnected) registerUser(String(address));
  }, [isConnected, address]);

  return (
    <div className="relative flex flex-col h-full">
      <div className="w-full flex justify-between items-center px-4 py-2.5 border-b-thin border-[#00000052]">
        {chain === 43113 ? (
          <a
            href="http://faucet.0vb.xyz"
            className="hover:bg-slate-200"
            target="_blank"
            rel="noreferrer"
          >
            <button className="p-2 w-fit rounded border-thin border-gray-400 ">
              Get Test USDC
            </button>
          </a>
        ) : <div className="w-25">
                  <h1 className="font-bold text-lg">
        {location.pathname === "/mint" && (
            <span className="font-medium text-xl">Position</span>
          )}
          {location.pathname === "/swap" && (
            <span className="font-medium text-xl">Swap</span>
          )}
          {location.pathname === "/liquidation" && (
            <span className="font-medium text-xl">Liquidation</span>
          )}
          {location.pathname === "/detail" && (
            <span className="font-medium text-xl">Liquidation</span>
          )}
          {location.pathname === "/transactions" && (
            <span className="font-medium text-xl">Transactions</span>
          )}
        {/* {location.pathname === "/transfer" && (
            <span className="font-medium text-xl">Transfer</span>
          )} */}
        </h1>
          </div>}

        <div>
          <div className="flex justify-around items-center text-xs space-x-2">
            {address && (
              <div
                onClick={() => setShow(!show)}
                className="bg-gray-200 flex p-1 justify-center items-center rounded-full"
              >
                <img
                  src={
                    chain === 43114
                      ? "/images/avax.png"
                      : "/images/avax-dark.png"
                  }
                  className="h-5"
                  alt=""
                />
              </div>
            )}
            {show && (
              <div
                className="absolute flex flex-column p-2 rounded text-white"
                style={{
                  marginTop: 150,
                  backgroundColor: "#241F17",
                }}
              >
                <button
                  onClick={() => {
                    switchNetwork(43114);
                    setShow(false);
                  }}
                  className="flex p-1 rounded hover:bg-orange-700"
                >
                  <img src="/images/avax.png" width={20} alt="" />
                  <p className="ml-2 text-xs">Avalanche C-Chain</p>
                </button>
                <button
                  onClick={() => {
                    switchNetwork(43113);
                    setShow(false);
                  }}
                  className="flex p-1 rounded mt-2  hover:bg-orange-700"
                >
                  <img src="/images/avax-white.png" width={20} alt="" />
                  <p className="ml-2 text-xs">Fuji Testnet</p>
                </button>
              </div>
            )}

            <button
              onClick={() => open()}
              className="rounded-full ml-2  text-white bg-[#FB7F37] px-3 py-1.5 pb-2 connect"
            >
              {address
                ? `${address?.slice(0, 5)} ... ${address?.slice(38, 42)}`
                : "Connect"}
            </button>
          </div>
        </div>
      </div>

      <div className="flex-1 pb-20 overflow-y-scroll">{children}</div>

      <div
        ref={footerRef}
        className="fixed w-full text-black bg-white bottom-0 flex border-t border-[#00000052] justify-around py-2.5"
      >
        <Link
          to="/mint"
          className="flex flex-col items-center justify-center position"
        >
          <PiLayout
            className={location.pathname === "/mint" ? "text-[#FB5F33]" : ""}
          />
          <p
            style={{
              color: location.pathname === "/mint" ? "#FB5F33" : "",
            }}
            className="text-xs"
          >
            Position
          </p>
        </Link>
        <Link
          to="/swap"
          className="flex flex-col items-center justify-center swap"
        >
          <BsArrowRepeat
            className={location.pathname === "/swap" ? "text-[#FB5F33]" : ""}
          />
          <p
            style={{
              color: location.pathname === "/swap" ? "#FB5F33" : "",
            }}
            className="text-sm"
          >
            Swap
          </p>
        </Link>
        <Link
          to="/transfer"
          className="flex flex-col items-center justify-center"
        >
          <LuArrowUpRightFromCircle
            className={
              location.pathname === "/transfer" ? "text-[#FB5F33]" : ""
            }
          />
          <p
            style={{
              color: location.pathname === "/transfer" ? "#FB5F33" : "",
            }}
            className="text-sm h-4"
          >
            Transfer
          </p>
        </Link>
        {/* <Link
          to="/transactions"
          className="flex flex-col items-center justify-center"
        >
          <PiCurrencyDollar
            className={
              location.pathname === "/transactions" ? "text-[#FB5F33]" : ""
            }
          />
          <p
            style={{
              color: location.pathname === "/transactions" ? "#FB5F33" : "",
            }}
            className="text-sm h-4"
          >
            Transactions
          </p>
        </Link> */}

        <Link
          to="/liquidation"
          className="flex flex-col items-center justify-center"
        >
          <IoArrowRedoOutline
            className={
              location.pathname === "/liquidation" ? "text-[#FB5F33]" : ""
            }
          />
          <p
            style={{
              color: location.pathname === "/liquidation" ? "#FB5F33" : "",
            }}
            className="text-sm"
          >
            Liquidation
          </p>
        </Link>
      </div>
      <FloatingButton />
      <GuideIntro
        isOpen={showGuide}
        setIsOpen={setShowGuide}
        driver={TourGuide}
      />
      <GuideOutro
        isOpen={showGuideCompleted}
        setIsOpen={setShowGuideCompleted}
      />
    </div>
  );
};

export default MobileLayout;
